import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import config from '../../config.json';

export const RoundelSettings = ({ teams, nations, roundels, updateRoundels }) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [nationId, setNationId] = useState(null);

  const handleOpenRoundelMenu = (event, nation) => {
    setNationId(nation);
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseRoundelMenu = () => {
    setAnchorElNav(null);
  };

  const handleChangeRoundelMenu = (roundel) => {
    roundels[nationId] = roundel;
    updateRoundels(roundels);
    handleCloseRoundelMenu();
    return;
  };

  return (
    <Box className='RoundelSettings'>
      { teams.map((team, index) => {
        return (
          <Box sx={{ pt: 1 }} key={team.name + index}>
            <Typography variant='h5' >
              {team.name}
            </Typography>
            <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 3 }} sx={{pt: 1}}>
            { Object.keys(roundels).map((id) => {
              if (team.players.includes(id)) {
                return (
                  <Grid item xs={1} sm={1} md={1} key={team + id}>
                    <Box className='roundelChange-container hoverHand' sx={{
                        p: 1,
                        backgroundColor: 'primary.mainAlpha20',
                        alignItems: 'center',
                      }}
                      onClick={(event) => {handleOpenRoundelMenu(event, id)}}
                      >
                      <Typography sx={{lineHeight: '20px' }}>
                        {nations[id]}
                      </Typography>
                      <img 
                        src={roundels[id]}
                        alt={'roundel ' + nations[id]}
                        width='40px'
                        height='40px'
                        // onClick={() => {handleOnClick(nation)}}
                      />
                    </Box>
                  </Grid>
                )
              }
            })}
            </Grid>
          </Box>              
        )
      })}
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseRoundelMenu}
        sx={{ maxHeight: '25%' }}
      >
      { config.ROUNDELS.map((roundel, index) => (
        roundel.NAME === 'Divider' 
        ? <Divider key={roundel.NAME + index} />
        : <MenuItem value={roundel.URL} key={roundel.NAME + index} onClick={() => {handleChangeRoundelMenu(roundel.URL)}}>
            <ListItemIcon>
              <img 
                src={roundel.URL}
                alt={'roundel ' + roundel.NAME}
                width='24px'
              />
            </ListItemIcon>
            <ListItemText>{roundel.NAME}</ListItemText>
          </MenuItem>
      ))}
      </Menu>
    </Box>
  );
}