import { BasicPage } from "../components/BasicPage";
import PriorityHigh from "@mui/icons-material/PriorityHigh";
import { AppBar } from "../components/AppBar";
import Box from "@mui/material/Box";

export const ErrorPage = (props) => {
  return (
    <div>
      <AppBar
        pages={[
          { label: "Home", path: "/" },
          // { label: "Login", path: "/login" }
        ]}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <BasicPage title={"Oops! " + props.error} icon={<PriorityHigh />} />
      </Box>
    </div>
  ); 
};
