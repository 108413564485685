import { useState, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useQuery } from "../hooks/useQuery";

const IMG_URL = "../../img/games/";

export default function ImageItemPicker({ url, selectedID, onClick}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [items, setItems] = useState({});
  const [description, setDescription] = useState("I use Popover.");

  const handlePopoverOpen = (event, index) => {
    console.log('data', data);
    console.log('index', index);
    console.log('game', data[index]);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  let { data } = useQuery("get", url);

  // let dataSorted = null;

  useEffect(() => {
    if (data) {
      // sort by name
      data.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      setItems(data);
    }
  }, [data])

  return (
    <Box>
      <ImageList sx={{ width: '100%', maxHeight: 458 }} cols={3} rowHeight={150}>
        {items && items.length && items.map((item, index) => (
          <ImageListItem key={item._id} className="hoverOpacity" onClick={() => {onClick(item)}}>
            <img
              src={IMG_URL + item.image}
              alt={item.name}
              loading="lazy"
              className="game_logo"
            />
            <ImageListItemBar
              title={item.name}
              subtitle={item.author}
              sx={{ backgroundColor: item._id === selectedID ? "secondary.mainAlpha" : "primary.mainAlpha75", cursor: "pointer", }}
              // actionIcon={
              //   <IconButton 
              //     sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
              //     aria-label={`info about ${item.title}`}
              //     onMouseEnter={(event) => {handlePopoverOpen(event, index)}}
              //     onMouseLeave={handlePopoverClose}
              //   >
              //     <InfoIcon />
              //   </IconButton>
              // }
            />
          </ImageListItem>
        ))}
      </ImageList>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{description}</Typography>
      </Popover>
    </Box>
  );
}