import { useEffect, useState } from "react";
import { useOutlet } from "react-router-dom";
import { AppBarPlay } from "./AppBarPlay";
import BottomNavigation from "@mui/material/BottomNavigation";
import Box from "@mui/material/Box";
import gameStore from '../stores/gameStore';
import uiStore from '../stores/uiStore';
import { observer } from 'mobx-react-lite';
import { NationalObjectivesDrawer } from './NationalObjectivesDrawer';
import { HistoryDrawer } from './HistoryDrawer';
import { DeclarationOfWarDialog } from './dialogs/DeclarationOfWarDialog';
import { RelationsDialog } from './dialogs/RelationsDialog';
import { StatsDialog } from './dialogs/StatsDialog';
import { RoundelSettingsDialog } from './dialogs/RoundelSettingsDialog';

export const PlayLayout = observer(({ user }) => {
  const outlet = useOutlet();

  const [pages, setPages] = useState([]);
  const [roundelsOpen, setRoundelsOpen] = useState(false);

  useEffect(() => {
    let tempPages = [];

    // console.log('path check roles', user);      
    if (user && user.roles) {
      // console.log('path check roles', user.roles);
      if (user.roles.includes('creator')) {
        tempPages.push({ label: "Home", path: "/game" });
        tempPages.push({ label: "Roundels", function: () => {setRoundelsOpen(true)} });
        tempPages.push({ label: "Settings", path: "/user/settings" });
        tempPages.push({ label: "Profile", path: "/play" });
      }
      else {
        tempPages.push({ label: "Roundels", function: () => {setRoundelsOpen(true)} });
      }
    }

    setPages(tempPages);
  }, [user]);
  
  return (
    <div>
      <AppBarPlay
        pages={pages}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        sx={{
          backgroundColor: "background.contentFrame"
        }}
      >
        {outlet}
      </Box>
      { gameStore.isPreprocessed &&
        <Box>
          <NationalObjectivesDrawer />
          <HistoryDrawer />
          <DeclarationOfWarDialog
            open={uiStore.dialog.declarationOfWar}
            fullScreen={false}
            attacker={uiStore.attacker}
            defenders={uiStore.defenders}
            territory={uiStore.territory}
            roundels={gameStore.players.roundels}
            handleClose={() => {uiStore.toggleDialog('declarationOfWar', false)}} 
            handleConfirm={() => {
              gameStore.setWarStatus(uiStore.attacker, uiStore.defenders[0], true, true);
              uiStore.callback();
            }}
          />
          <RelationsDialog 
            open={uiStore.dialog.relations} 
            handleClose={() => {uiStore.toggleDialog('relations', false)}} 
            playerOrder={gameStore.players.order}
            roundels={gameStore.players.roundels} 
            currentPlayer={gameStore.players.current} 
            warStatus={gameStore.players.atWar} 
            handleOnClick={(player, nation, status) => {gameStore.setWarStatus(player, nation, status, true)}}
          />
          <StatsDialog
            open={uiStore.dialog.stats}
            fullScreen={false}
            handleClose={() => {uiStore.toggleDialog('stats', false)}} 
          />
        </Box>
      }
      <BottomNavigation className="BottomNavigation" sx={{
          position: "absolute",
          bottom: "0px",
          left: "0px",
          width: "100%",
          color: "primary.contrastText",
          backgroundColor: "primary.main",
          boxShadow: "0px -2px 4px -1px rgba(0, 0, 0, 0.2),0px -4px 5px 0px rgba(0, 0, 0, 0.14),0px -1px 10px 0px rgba(0, 0, 0, 0.12)",
          overflow: "hidden",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}>
        {/*{ uiStore.userState.state === uiStore.UI_STATE_PLAYING && gameStore.status.loaded && uiStore.getSessionUserState("page") === "/" && <VictoryConditions /> }*/}
        <div>
          Test: { JSON.stringify(gameStore.status) }
        </div>
      </BottomNavigation>
      <RoundelSettingsDialog
        open={roundelsOpen}
        teams={gameStore.players.teams}
        nations={gameStore.players.names}
        initRoundels={gameStore.players.roundels}
        handleClose={() => {setRoundelsOpen(false)}}
      >
      </RoundelSettingsDialog>
    </div>
  );
});
