import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const ProfileMenu = ({ pages }) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavigate = (path) => {
    handleCloseNavMenu();

    if (path) {
      navigate(path);
    }
  };

  const handleFunction = (fn) => {
    handleCloseNavMenu();

    fn();
  };
  
  return (
    <Box>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <AccountCircleIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        // sx={{
        //   display: { xs: "block", md: "none" }
        // }}
      >
        {pages?.map((page) => (
          <MenuItem
            key={page.label}
            onClick={() => {
              if (page.path)
                handleNavigate(page.path)
              else if (page.function)
                handleFunction(page.function)
            }}
          >
            <Typography textAlign="center">{page.label}</Typography>
          </MenuItem>
        ))}
  {/*              {!user && (
          <MenuItem key={"login"} onClick={() => navigate("/login")}>
            <Typography textAlign="center">Login</Typography>
          </MenuItem>
        )}*/}
        {!!user && user.roles.includes('creator') && (
          <MenuItem key={"logout"} onClick={logout}>
            <Typography textAlign="center">Logout</Typography>
          </MenuItem>
        )}
        {!!user && !user.roles.includes('creator') && (
          <MenuItem key={"logout"} onClick={logout}>
            <Typography textAlign="center">Leave game</Typography>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};