  import Button from '@mui/material/Button';
  import Dialog from '@mui/material/Dialog';
  import DialogActions from '@mui/material/DialogActions';
  import DialogContent from '@mui/material/DialogContent';
  import DialogTitle from '@mui/material/DialogTitle';
  import IconButton from '@mui/material/IconButton';
  import CloseIcon from '@mui/icons-material/Close';

  const StandardDialog = (props) => {
    const { title, label, children, open, handleClose, onClose, fullScreen, actions, PaperProps } = props;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        // onClose={() => {handleClose()}}
        aria-labelledby={label}
        PaperProps={PaperProps}
        maxWidth='md'
        // sx={{
        //   backdropFilter: "blur(2px)",
        //   //other styles here
        // }}
      >
        <DialogTitle id="dialog">
          {title}
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={() => {handleClose()}}
              sx={{
                position: 'absolute',
                right: 12,
                top: 12,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        {actions && 
          <DialogActions>
            {actions()}
          </DialogActions>
        }
      </Dialog>
    );
  };

  export default StandardDialog;