import { Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import Typography from "@mui/material/Typography";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import gameStore from '../stores/gameStore';

export const NationalObjectives = observer(({ handleChange }) => {

  return(
    <div className='drawer-container'>
      <Typography align='left' variant='h5' gutterBottom={true} className='AAFont stroke drawer-title' stroketext='National objectives'> 
        National objectives
      </Typography>
      <Divider />
      <List className='drawer-content'>
      { gameStore.objectives.map((obj, index) => (
          gameStore.getObjective(index).player === gameStore.players.current ?
            <Fragment key={gameStore.getObjective(index)._id}>
              <ListItem>
               <div className='NationalObjectives-container'>
                <div>
                  <Typography style={{ fontWeight: 600 }}> 
                    {gameStore.getObjective(index).name}
                  </Typography>
                  {gameStore.getObjective(index).description}
                </div>
                <div className='NationalObjectives-bonus'>
                  <Typography align='center' className={['AAFont', 'stroke', 'NationalObjectives-ipc'].join(' ')} stroketext={obj.ipc}> 
                    {obj.ipc}
                  </Typography>
                  <div className='NationalObjectives-toggle'>
                  {gameStore.getObjective(index).type === 'manual' && 
                  <Switch
                    checked={obj.status}
                    onChange={() => {handleChange(index)}}
                    value={gameStore.getObjective(index)._id}
                    // color='secondary'
                  />}
                  </div>
                </div>
              </div>

              </ListItem>
              <Divider />
            </Fragment>


           
          :
            null
        ))
      }
      </List>
    </div>
  )
});