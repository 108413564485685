import axios from "axios";
import uiStore from "../stores/uiStore";
import config from '../config.json';

const _getQuery = (url) => {
  return axios
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error?.response?.status > 400) {
        if (error.response.status === 401) {
          uiStore.setUserState('loggedIn', false);
        }
        // if (navigate) {
        //   console.error('NAVIGATE')
        //   navigate(window.location.pathname, { replace: true, state: error?.response?.status});
        // }

        uiStore.setRedirect({path: window.location.pathname, state: error.response.status});
      }

      throw error;
    });
};

const _postQuery = (url, payload, navigate) => {
  return axios
    .post(url, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error?.response?.status > 400) {
        if (error.response.status === 401) {
          uiStore.setUserState('loggedIn', false);
        }
        // if (navigate) {
        //   navigate(window.location.pathname, { replace: true, state: error?.response?.status});
        // }

        uiStore.setRedirect({path: window.location.pathname, state: error.response.status});
      }

      throw error;
    });
};

const _putQuery = (url, payload, navigate) => {
  return axios
    .put(url, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error?.response?.status > 400) {
        if (error.response.status === 401) {
          uiStore.setUserState('loggedIn', false);
        }
        // if (navigate) {
        //   navigate(window.location.pathname, { replace: true, state: error?.response?.status});
        // }

        uiStore.setRedirect({path: window.location.pathname, state: error.response.status});
      }

      throw error;
    });
};

const _patchQuery = (url, payload, navigate) => {
  return axios
    .patch(url, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error?.response?.status > 400) {
        if (error.response.status === 401) {
          uiStore.setUserState('loggedIn', false);
        }
        // if (navigate) {
        //   navigate(window.location.pathname, { replace: true, state: error?.response?.status});
        // }

        uiStore.setRedirect({path: window.location.pathname, state: error.response.status});
      }

      throw error;
    });
};

const _deleteQuery = (url, navigate) => {
  return axios
    .delete(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error?.response?.status > 400) {
        if (error.response.status === 401) {
          uiStore.setUserState('loggedIn', false);
        }
        // if (navigate) {
        //   navigate(window.location.pathname, { replace: true, state: error?.response?.status});
        // }

        uiStore.setRedirect({path: window.location.pathname, state: error.response.status});
      }

      throw error;
    });
};

const getPlays = (navigate) => {   
  return _getQuery(config.BASE_URL + config.API_URL_PLAYS, navigate);
};

const getPlay = (id, navigate) => {
  return _getQuery(config.BASE_URL + config.API_URL_PLAYS + id, navigate);
};

const postPlay = (play, navigate) => {
  return _postQuery(config.BASE_URL + config.API_URL_PLAYS, play, navigate);
};

const patchPlay = (id, patch, navigate) => {
  return _patchQuery(config.BASE_URL + config.API_URL_PLAYS + id, patch, navigate);
}

const deletePlay = (id, navigate) => {
  return _deleteQuery(config.BASE_URL + config.API_URL_PLAYS + id, navigate);
};

const getRuleset = (id, navigate) => {
  return _getQuery(config.BASE_URL + config.API_URL_RULES + id, navigate);
};

const getScenario = (id, navigate) => {
  return _getQuery(config.BASE_URL + config.API_URL_SCENARIOS + id, navigate);
};

const getMap = (id, navigate) => {
  return _getQuery(config.BASE_URL + config.API_URL_MAPS + id, navigate);
};

export const QueryService = {
  getPlays,
  getPlay,
  postPlay,
  patchPlay,
  deletePlay,
  getRuleset,
  getScenario,
  getMap,
}