import { Fragment } from "react";
// import useSnackbar from "../hooks/useSnackbar";
import Snackbar from "@mui/material/Snackbar";
import Slide from '@mui/material/Slide';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import MuiAlert from "@mui/material/Alert";

// const Alert = forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

const SlideTransition = (props) => {
  return <Slide {...props} direction="up" />;
}

const Message = (message) => {
  return <span sx={{ width: "100%", textAlign: "center" }}> {message?.text} </span>
}


export const SnackbarNotification = (props) => {
  // const { message, removeMessage } = useSnackbar();
  const message = props?.message;
  console.log('SnackbarNotification', props);

  const handleClose = (event, reason) => {
    console.log("handleClose", event, reason);
    if (reason === "clickaway") {
      return;
    }

    props.handleCloseSnackbar();
  };

  const action = (
    <Fragment>
  {/*    <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>*/}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  return (
    <Snackbar
      open={!!message}
      // autoHideDuration={config.SNACKBAR_DURATION}
      TransitionComponent={SlideTransition}
      anchorOrigin={{
        vertical: props?.vertical,
        horizontal: props?.horizontal,
      }}
      onClose={handleClose}
      sx={{ 
        marginBottom: "40px",
        '& .MuiSnackbarContent-root': {
          // display: 'block',
          borderRadius: "4px 4px 0px 0px",
          // textAlign: "center"
        }
      }}
      message={message?.text}
      // message={<span className="text-center">{message?.text}</span>}
      // transitionDuration={1000}
      action={props.closeButton ? action : null}
    >
{/*      <Alert onClose={handleClose} severity={message?.severity ? message?.severity : "info"} sx={{ width: "100%" }}>
        {message?.text}
      </Alert>*/}
    </Snackbar>
  );
}
