import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { AuthService } from "../services/auth.service";

export const SignUpPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({name: "", email: "", password: ""});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const handleContinue = (event) => {
    navigate("/login", { replace: true });
  }

  const handleSignUp = (event) => {
    event.preventDefault();

    setLoading(true);
    setErrors({name: "", email: "", password: ""});
    setMessage("");

    AuthService.signup(name, email, password).then(
      () => {
        setSuccess(true);
      },
      (error) => {
        setLoading(false);

        const err = error.response.data.errors;
        if (err) {
          setErrors(err);
        }
        else {
          const resMessage =
            (error.response &&
              error.response.statusText) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
        }
      }
    );
  };

  return (
    <Container component="main" maxWidth="xs" 
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <AssignmentIndOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {!success ? "Sign Up Here" : "Sign Up Successfull"}
        </Typography>
        {message &&
          <div className="error-message">
            <div>
              Oops something went wrong!
            </div>
            <div>
              {message}
            </div>
          </div>
        }
        {!success && 
          <Box component="form" onSubmit={handleSignUp} noValidate sx={{ mt: 1 }}>
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              autoFocus
              onChange={event => setName(event.target.value)}
              helperText={errors.name}
              error={errors.name ? true : false}
            />
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={event => setEmail(event.target.value)}
              helperText={errors.email}
              error={errors.email ? true : false}
            />
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={event => setPassword(event.target.value)}
              helperText={errors.password}
              error={errors.password ? true : false}
            />
    {/*        <Select
              // margin="normal"
              fullWidth
              id="country"
              label="Country"
              // name="country"
              // autoComplete="country"
              // defaultValue="nl"
              displayEmpty
              // renderValue={(value) => {return value ? value : "Choose a Country";}}
              value={selectedCountry}
              onChange={(e) => selectCountryHandler(e.target.value)}
            >
              {!!countryArr?.length &&
                countryArr.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
            </Select>*/}
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}
              // loadingPosition="end"
            >
              Sign Up
            </LoadingButton>
            <Grid container>
              <Grid item>
                <RouterLink to="/login">
                  <Link href="#" variant="body2">
                    {"Already have an account? Login"}
                  </Link>
                </RouterLink>
              </Grid>
            </Grid>
          </Box>
        }
        {success && 
          <Box>
            <p>
              You should receive a validation email shortly.
            </p>
            <Button
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2 }}
              onClick={handleContinue}
            >
              Continue
            </Button>
          </Box>
        }
      </Box>
    </Container>
  );
};
