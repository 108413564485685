import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
// import { useStore } from "../hooks/useStores";
import gameStore from '../stores/gameStore';
import { TileMap } from "../components/TileMap";
import { observer } from 'mobx-react-lite';
import StandardDialog from '../components/dialogs/StandardDialog';
  import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const PlayPage = observer(() => {
  const isPlaying = gameStore.isPlaying;
  const isReady = gameStore.isReady;
  const isPreprocessed = gameStore.isPreprocessed;

  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const actions = () => {
    return (
      <Button
        onClick={() => {
          navigate('/');
        }}
      >
        Go Home
      </Button>
    );
  };


  if (isPreprocessed) {
    return ( 
      <Box>
        <TileMap type="game" />
        <StandardDialog
          title="Please wait"
          open={isReady && !isPlaying}
        >
          The game has not yet started.
        </StandardDialog>
        <StandardDialog
          title="Game over"
          open={!isReady && !isPlaying}
          actions={actions}
        >
          Please go back to the home page.
        </StandardDialog>
      </Box>
    )
  }
  else if (gameStore.playId) {
    return (
      <Box className="centerContainer">
        <CircularProgress disableShrink color="secondary" /><br />
        <Typography align='center' variant='h6' gutterBottom={true} className='AAFont stroke' stroketext={gameStore.loadingState}> 
          {gameStore.loadingState}
        </Typography>
      </Box>
    )
  }
  else {
    if (user && user.roles && user.roles.indexOf('creator') != -1) {
      navigate('/game');
    } 
    else {
      logout();
    }
  }

  // if (isPreprocessed) {
  //   if (isPlaying) {
  //     return ( <TileMap type="game" /> )
  //   }
  //   else if (isReady) {
  //     return (
  //       <Box className="centerContainer">
  //         <Typography align='center' variant='h6' gutterBottom={true} className='AAFont stroke' stroketext='Game not yet running, please wait'> 
  //           Game not yet running, please wait
  //         </Typography>
  //       </Box>
  //     )
  //   }
  //   else {
  //     return (
  //       <Box className="centerContainer">
  //         <Typography align='center' variant='h6' gutterBottom={true} className='AAFont stroke' stroketext='OOPS!!!'> 
  //           OOPS!!!
  //         </Typography>
  //       </Box>
  //     )
  //   }
  // }
  // else {
  //   return (
  //     <Box className="centerContainer">
  //       <CircularProgress disableShrink color="secondary" /><br />
  //       <Typography align='center' variant='h6' gutterBottom={true} className='AAFont stroke' stroketext={gameStore.loadingState}> 
  //         {gameStore.loadingState}
  //       </Typography>
  //     </Box>
  //   )
  // }
});