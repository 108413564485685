import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../services/auth.service";

export const ResendEmailVerificationPage = () => {
  const params = useParams();
  
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({email: ""});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const handleContinue = (event) => {
    navigate("/login", { replace: true });
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    setLoading(true);
    setErrors({email: ""});
    setMessage("");

    AuthService.resendVerificationEmail(email).then(
      () => {
        setSuccess(true);
        setLoading(false);
        console.log('success');
      },
      (error) => {
        console.log('error');
        setLoading(false);

        const err = error.response.data.errors;
        if (err) {
          setErrors(err);
        }
        else {
          const resMessage =
            (error.response && 
              error.response.data && 
              error.response.data.message) ||
            "Oops something went wrong!"; 

          setMessage(resMessage);
        }
      }
    );
  };

  return (
    <Container component="main" maxWidth="xs" 
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Account verification
        </Typography>
        {!message && !success &&
          <p className="error-message">Verification link has expired</p>
        }
        {message && <p className="error-message">{message}</p>}
        {success &&
          <p style={{textAlign: "center"}}>
            If this is a known and unverified account,<br />
            you should receive a validation email shortly.
          </p>
        }
        {!success &&
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={event => setEmail(event.target.value)}
              helperText={errors.email}
              error={errors.email ? true : false}
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}
              // loadingPosition="end"
            >
              Resend Link
            </LoadingButton>
            <Grid container>
              <Grid item>
                <RouterLink to="/login">
                  <Link href="#" variant="body2">
                    {"Account already verified? Login"}
                  </Link>
                </RouterLink>
              </Grid>
            </Grid>
          </Box>
        }
        {success &&
          <Box>
            <Button
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2 }}
              onClick={handleContinue}
            >
              Continue
            </Button>
          </Box>
        }
      </Box>
    </Container>
  );
};
