import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Link as RouterLink } from "react-router-dom";
import { AuthService } from "../services/auth.service";
// import { useStore } from "../hooks/useStores";
import gameStore from '../stores/gameStore';
import uiStore from '../stores/uiStore';
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

// import Button from "@mui/material/Button";
// import { useNavigate } from "react-router-dom";

export const JoinPage = () => {
  const [pin, setPin] = useState("");
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  // const gameStore = useStore("gameStore");
  const { login } = useAuth();

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    setLoading(true);
    setErrors({pin: ""});
    setMessage("");
        
    AuthService.join(pin).then(
      (data) => {
        // If not already logged in use the regular 'join user'
        if (AuthService.getCurrentUser() == null) {
          console.log('LOGIN AS JOIN PLAYER');
          login(data.user);
        }

        setLoading(false);
        gameStore.setPlay(data?.play?.id);
        navigate("/play")
      },
      (error) => {
        console.log('error', error.response);
        setLoading(false);

        // setMessage(error.Message);
        
        const err = error.response?.data?.errors;
        if (err) {
          setErrors(err);
        }
        else {
          let resMessage = "Oops something went wrong!"
          
          if (error?.response?.data?.message) {
            resMessage = error.response.data.message;
          }
          
          if (error?.response?.status === 400) {
            setErrors({pin: 'Incorrect game pin.'});
          }
          else {
            setMessage(resMessage);
          }
          // setMessage(JSON.stringify(error.message));
        }
      }
    );
  };


  return (
    <Container component="main" maxWidth="xs" 
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LocalActivityIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Join Game
        </Typography>
        {message && <p className="error-message">{message}</p>}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            id="pin"
            label="Pin"
            name="pin"
            autoComplete="pin"
            autoFocus
            onChange={event => setPin(event.target.value)}
            helperText={errors.pin}
            error={errors.pin ? true : false}
          />
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
            // loadingPosition="end"
          >
            Join Game
          </LoadingButton>
          <Grid container>
            <Grid item>
              <RouterLink to="/login">
                <Link href="#" variant="body2">
                  {"Want to create a game? Login"}
                </Link>
              </RouterLink>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};
