import { Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Flag from 'mdi-material-ui/Flag';
import Fire from 'mdi-material-ui/Fire';
import FlagOutline from 'mdi-material-ui/FlagOutline';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import SwordCross from 'mdi-material-ui/SwordCross';
import Close from 'mdi-material-ui/Close';
import BackupRestore from 'mdi-material-ui/BackupRestore';
import InsightsIcon from '@mui/icons-material/Insights';

import gameStore from '../stores/gameStore';
import uiStore from '../stores/uiStore';

export const History = observer(({handleChange}) => {

  return(
    <div className='drawer-container'>
      <div className='drawer-title'>
        <Typography align='left' variant='h5' gutterBottom={true} className='AAFont stroke' stroketext='Game log'> 
          Game log
        </Typography>
      </div>
      <Divider />
      <List className='drawer-content' onMouseLeave={() => {gameStore.hoverEventClear()}}>
      { gameStore.events.map((event, index) => (
          <Fragment key={index}>
            <ListItem className='History-event' onMouseEnter={() => {gameStore.hoverEvent(index)}}>
              {event.type === 'capture' ?
                <Fragment>
                  <img 
                    className='roundelSmall paddingRight6'
                    src={gameStore.players.roundels[event.player]} 
                    alt={'roundel ' + event.player} 
                  />
                  {' '}
                  {'captured ' + gameStore.territories[event.info.index].name}
                </Fragment>
              :event.type === 'contest' ?
                <Fragment>
                  <img 
                    className='roundelSmall paddingRight6'
                    src={gameStore.players.roundels[event.player]} 
                    alt={'roundel ' + event.player} 
                  />
                  {'contested ' + gameStore.territories[event.info.index].name}
                </Fragment>
              :event.type === 'liberate' ?
                <Fragment>
                  <img 
                    className='roundelSmall paddingRight6'
                    src={gameStore.players.roundels[event.player]} 
                    alt={'roundel ' + event.player} 
                  />
                  {'liberated ' + gameStore.territories[event.info.index].name}
                </Fragment>
              :event.type === 'revert' ?
                <Fragment>
                  <img 
                    className='roundelSmall paddingRight6'
                    src={gameStore.players.roundels[event.info.from]} 
                    alt={'roundel ' + event.info.from} 
                  />
                  {'reverted ' + gameStore.territories[event.info.index].name + ' to'}
                  <img 
                    className='roundelSmall paddingLeft6'
                    src={gameStore.players.roundels[event.info.to]} 
                    alt={'roundel ' + event.info.to} 
                  />
                </Fragment>
              :event.type === 'objective' ?
                <Fragment>
                  <img 
                    className='roundelSmall paddingRight6'
                    src={gameStore.players.roundels[event.player]} 
                    alt={'roundel ' + event.player} 
                  />
                  {'"' + gameStore.getObjective(event.info.index).name + '" has been ' + (event.info.status === true ? 'enabled' : 'disabled')}
                </Fragment>
              :event.type === 'war-peace' ?
                <Fragment>
                  <img 
                    className='roundelSmall paddingRight6'
                    src={gameStore.players.roundels[event.info.player]} 
                    alt={'roundel ' + event.info.player} 
                  />
                  {event.info.status === true ?
                    'declared war on'
                  :
                    'signed a peace treaty with'
                  }
                  {event.info.nations?.map((nation, i, {length}) => (
                    <Fragment>
                      <img 
                        className={i === 0 && length > 1 ? 'roundelSmall paddingLeft6' : 'roundelSmall paddingLeft6 paddingRight6'}
                        src={gameStore.players.roundels[nation]} 
                        alt={'roundel ' + nation}
                      />
                      {i + 2 === length ? 'and'
                      : i + 1 < length ? ',' : ''}
                    </Fragment>
                  ))}
                </Fragment>
              :null}
                <ListItemSecondaryAction>
                  <IconButton aria-label='Comments'>
                    {/* {event.hover ? */}
                    {/* {gameStore.eventHovered === index ? */}
                    {gameStore.eventHovered === index ?
                      <Close />
                    :event.type === 'capture' ?
                      <Flag />
                    :event.type === 'contest' ?
                      <Fire />
                    :event.type === 'liberate' ?
                      <FlagOutline />
                    :event.type === 'revert' ?
                      <BackupRestore />
                    :event.type === 'objective' ?
                      <WorkspacePremiumIcon />
                    :event.type === 'war-peace' ?
                      <SwordCross />
                    :null}
                  </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </Fragment> 
        ))
      }
      </List>
      <Divider />
      <Button startIcon={<InsightsIcon />} onClick={() => { uiStore.toggleDialog('stats', true); }}>
        Show Graphs
      </Button>
    </div>
  )
});