import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";

export const useQuery = (method, url, reload) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [apiData, setApiData] = useState();
  
  useEffect(() => {
    console.log('USEQUERY URL', method, url);

    try {
      switch (method) {
        default:
        case "get":
          axios
            .get(url)
            .then(response => {
              setApiData(response.data);
              console.log('useQuery data', response.data);
            })
            .catch(error => {
              if (error?.response?.status > 400) {
                navigate(location.pathname, { replace: true, state: error?.response?.status});
              }
            });
          break;
        case "put":
          axios
            .put(url)
            .then(response => {
              setApiData(response.data);
            })
            .catch(error => {
              if (error?.response?.status > 400) {
                navigate(location.pathname, { replace: true, state: error?.response?.status});
              }
            });
          break;
        case "post":
          axios
            .post(url)
            .then(response => {
              setApiData(response.data);
            })
            .catch(error => {
              if (error?.response?.status > 400) {
                navigate(location.pathname, { replace: true, state: error?.response?.status});
              }
            });
          break;
        case "delete":
          axios
            .delete(url)
            .then(response => {
              setApiData(response.data);
            })
            .catch(error => {
              if (error?.response?.status > 400) {
                navigate(location.pathname, { replace: true, state: error?.response?.status});
              }
            });
          break;
      }
    }
    catch (error) {
      console.log('useQuery error', error);
      if (error?.response?.status > 400) {
        navigate(location.pathname, { replace: true, state: error?.response?.status});
      }
    }
  }, [method, url, reload]);

  return { data: apiData }
}