import { useState, useEffect, Fragment } from "react";
import { withStyles } from '@mui/material/styles';
import PropTypes from 'prop-types';
import StandardDialog from './StandardDialog';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import ImageItemPicker from "../ImageItemPicker";
import ItemPicker from "../ItemPicker";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Select from '@mui/material/Select';
import config from '../../config.json';
import { QueryService } from '../../services/query.service';
import { useNavigate } from "react-router-dom";
import { RoundelSettings } from './RoundelSettings';

import gameStore from '../../stores/gameStore';

const steps = ['Game', 'Ruleset', 'Scenario', 'Map', 'Roundels', 'Name'];

export const GameSetupDialog = ({ open, fullScreen, handleClose, triggerReload }) => {
  // const [finished, setFinished] = useState(false);
  // const [saveGame, setSaveGame] = useState(false);
  const [errors, setErrors] = useState({game: ""});
  const [loading, setLoading] = useState(false);
  // const [message, setMessage] = useState("");

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState([false, false, false, false, false, false]);
  const [gameName, setGameName] = useState("");
  const [game, setGame] = useState({});
  const [rules, setRules] = useState({});
  const [scenario, setScenario] = useState({});
  const [map, setMap] = useState({});
  const [roundels, setRoundels] = useState({});
  const [nations, setNations] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (open === false) {
      handleReset();
    }
  }, [open]);

  const createGame = () => {
    // find roundels that are different from scenario's default
    let deltaRoundels = {};
    for (let nation of scenario.nations) {
      if (roundels.hasOwnProperty(nation._id)) {
        if (nation.roundel !== roundels[nation._id]) {
          deltaRoundels[nation._id] = roundels[nation._id];
        }
      }
    }

    const newGame = {
        name: gameName,
        game: game._id,
        rules: rules._id,
        scenario: scenario._id,
        map: map._id,
        roundels: deltaRoundels,
      };

    setLoading(true);

    QueryService.postPlay(newGame, navigate)
      .then((data) => {
        setLoading(false);
        // the 11000 Mongo code is for a duplication email error
        if (data.err === 11000) {
          setErrors({ game: data.message });
        }
        else {
          triggerReload();
          handleClose();
        }        
      });

    // console.log('create Game', result);
  };

  const handleContinue = (event) => {
    navigate("/game");
  }

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  // const handleComplete = () => {
  //   const newCompleted = completed;
  //   newCompleted[activeStep] = true;
  //   setCompleted(newCompleted);
  //   handleNext();
  // };

  const isStepComplete = () => {
    switch (activeStep) {
      case 0:
        return game?._id ? true : false;
      case 1:
        return rules?._id ? true : false;
      case 2:
        return scenario?._id ? true : false;
      case 3:
        return map?._id ? true : false;
      case 4:
        return roundels ? true : false;
      case 5:
        return gameName ? true : false;
      default:
        return false;
    }
  }

  const handleReset = () => {
    setActiveStep(0);
    setCompleted([false, false, false, false, false, false]);
    setGameName("");
    setGame({});
    setRules({});
    setScenario({});
    setMap({});
  };

  const selectGame = (game) => {
    setGame(game);
    handleNext();
  }

  const selectRules = (rules) => {
    setRules(rules);
    handleNext();
  }

  const selectScenario = (scenario) => {
    setScenario(scenario);

    let nationNames = {}
    let initRoundels = {}
    for (let nation of scenario.nations) {
      nationNames[nation._id] = nation.name;
      initRoundels[nation._id] = nation.roundel;
    }
    setNations(nationNames);
    setRoundels(initRoundels);

    handleNext();
  }

  const selectMap = (map) => {
    setMap(map);
    handleNext();
  }

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box sx={{ marginTop: '16px', textAlign: 'center', width: '100%' }}>
            <Typography>
              Choose the <b>game</b> you want to track:
            </Typography>
            <ImageItemPicker selectedID={game._id} onClick={selectGame} url={config.BASE_URL + config.API_URL_GAMES}/>
          </Box>
        );
      case 1:
        return (
          <Box sx={{ marginTop: '16px', textAlign: 'center' }}>
            <Typography>
              Choose the <b>ruleset</b> you want to use:
            </Typography>
            {game?.rules?.length > 0 
              ? <ItemPicker selectedID={rules._id} onClick={selectRules} url={config.BASE_URL + config.API_URL_RULES + game?.rules?.join(";")}/>
              : <Typography sx={{ margin: '16px', fontWeight: 'bold'}}>Oops no ruleset implemented yet!</Typography>
            }
          </Box>
        );
      case 2:
        return (
          <Box sx={{ marginTop: '16px', textAlign: 'center' }}>
            <Typography>
              Choose the <b>scenario</b> you want to play:
            </Typography>
            {game?.scenarios?.length > 0 
              ? <ItemPicker selectedID={scenario._id} onClick={selectScenario} url={config.BASE_URL + config.API_URL_SCENARIOS + game?.scenarios?.join(";")}/>
              : <Typography sx={{ margin: '16px', fontWeight: 'bold'}}>Oops no scenarios implemented yet!</Typography>
            }
          </Box>
        );
      case 3:
        return (
          <Box sx={{ marginTop: '16px', textAlign: 'center' }}>
            <Typography>
              Choose the <b>map</b> you want to use:
            </Typography>
            {game?.maps?.length > 0
              ? <ItemPicker selectedID={map._id} onClick={selectMap} url={config.BASE_URL + config.API_URL_MAPS + game?.maps?.join(";")}/>
              : <Typography sx={{ margin: '16px', fontWeight: 'bold'}}>Oops no maps implemented yet!</Typography>
            }
          </Box>
        );
      case 4:
        return ( 
              <Box sx={{ marginTop: '16px', width: '740px', textAlign: 'center', overflowX: 'hidden' }}>
                <Typography>
                  Choose the <b>roundels</b> you want to use:
                </Typography>
                <RoundelSettings teams={scenario.players.teams} nations={nations} roundels={roundels} updateRoundels={setRoundels} />
              </Box>
        )
      case 5:
        return (
          <Box sx={{ marginTop: '16px', textAlign: 'center' }}>
            <Typography>
              Give your game a <b>unique</b> name:
            </Typography>
            {errors.game && 
              <Typography className="error-message">
                {errors.game}
              </Typography>
            }
            <TextField
              variant="filled"
              margin="normal"
              required
              label="Name"
              name="name"
              onChange={event => setGameName(event.target.value)}
              value={gameName}
            />
          </Box>
        );
      default:
        return 'Unknown step';
    }
  }

  const actions = () => {
    return (
      <Box>
        <Button
          onClick={() => {handleNext()}}
          // color="secondary"
          disabled={isLastStep() || !isStepComplete()}
        >
          Next
        </Button>
        <Button
          onClick={() => {createGame()}}
          // color="secondary"
          disabled={!isLastStep() || !isStepComplete()}
        >
          Save
        </Button>
      </Box>
    );
  };

  return (
    <StandardDialog
      open={open}
      fullScreen={fullScreen}
      title='Game Setup'
      label='game-setup-dialog' 
      handleClose={handleClose}
      onClose={true}
      actions={actions}
    >
      <Box sx={{
        display: "relative",
        flexDirection: "column",
        overflow: "hidden",
        width: "750px",
      }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Stepper activeStep={activeStep} sx={{width: "100%"}}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          {getStepContent()}
        </Box>
      </Box>
    </StandardDialog>
  )
};