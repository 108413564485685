import {extendObservable, observable, action, computed, autorun, toJS} from 'mobx';
import gameStore from './gameStore';

const test = true;


export class StatisticsStore {

  constructor() {
    extendObservable(this, {
      trends: {
        territory: {},
        // ipc: {},
      },
    });
  }

  resetTerritoryTrend = action(() => {
    // Find initial number of territories
    const territories = gameStore.data.scenario.territories;
    let territoryTrendStart = {}

    for (let i in territories) {
      let territory = territories[i];
      let owner = gameStore.getParentNation(territory.own, 'economical');
      if (territoryTrendStart.hasOwnProperty(owner)) {
        territoryTrendStart[owner] += 1;
      }
      else {
        territoryTrendStart[owner] = 1;
      }
    }

    let territoryTrend = {};
    for (let [key, value] of Object.entries(territoryTrendStart)) {
      territoryTrend[key] = [value];
    }

    if (!territoryTrend.hasOwnProperty('CON')) {
      territoryTrend['CON'] = [0];
    }

    this.trends.territory = territoryTrend;
  });

  appendTerritoryTrend = action((from, to) => {
    from = gameStore.getParentNation(from, 'economical');
    to = gameStore.getParentNation(to, 'economical');

    for (let [key, value] of Object.entries(this.trends.territory)) {
      if (key === from) {
        this.trends.territory[key].push(value[value.length-1] - 1);
      }
      else if (key === to) {
        this.trends.territory[key].push(value[value.length-1] + 1);
      }
      else {
        this.trends.territory[key].push(value[value.length-1]);
      }
    }
  });

  getTerritoryTrend = () => {
    let output = [];

    for (let [key, value] of Object.entries(this.trends.territory)) {
      if (gameStore.getTeam(key) !== 'Neutrals' && gameStore.getTeam(key) !== undefined) {
        output.push({key: key, 
                    values: value, 
                    img: gameStore.players.roundels[key], 
                    color: (typeof gameStore.players.colors !== 'undefined' && gameStore.players.colors.hasOwnProperty(key)) ? gameStore.players.colors[key] : '#444' });
      }
    }

    return output;
  };

}

export default new StatisticsStore();

function logger(data, opt) {
  if (test) console.log(data, opt);
}