import { observer } from 'mobx-react-lite';
import Drawer from '@mui/material/Drawer';
import { History } from './History';

import gameStore from '../stores/gameStore';
import uiStore from '../stores/uiStore';

export const HistoryDrawer = observer(() => {
  
  const handleRemove = event => {
    // this.setState({ [name]: event.target.checked });
    console.log('handleRemove', event);
    gameStore.undoEvent(event);
    // gameStore.setObjectiveStatus(number, !gameStore.objectives[number].status, true);
  };

  return(
    <Drawer
      variant='persistent'
      anchor='right'
      classes={{
        paper: 'drawer', 
      }}
      PaperProps={{
        sx: {
          backgroundColor: 'background.drawer',
        }
      }}
      open={uiStore.drawers.history}
    >
      <History className='drawer-container' handleRemove={handleRemove} />
    </Drawer>
  )
});