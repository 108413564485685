import { useEffect } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import {getTerritoryTrend} from '../logic/statistics';

export const BarChart = ({id, type}) => {

  useEffect(() => {
    drawChart();
  }, [])

  const drawChart = () => {
    const margin = {top: 20, right: 30, bottom: 20, left: 20}
      , width = 800//window.innerWidth// - margin.left - margin.right // Use the window's width 
      , height = 400;//window.innerHeight;// - margin.top - margin.bottom; // Use the window's height

    const data = getTerritoryTrend();
    // console.log('data', data);
    const n = data[0].values.length;


    const range = Array.from(Array(n).keys());
    

    function hover(svg, path) {  
      if ("ontouchstart" in document) svg
          .style("-webkit-tap-highlight-color", "transparent")
          .on("touchmove", moved)
          .on("touchstart", entered)
          .on("touchend", left)
      else svg
          .on("mousemove", moved)
          .on("mouseenter", entered)
          .on("mouseleave", left)
      
      const dot = svg.append("g")
          .attr("display", "none");
    
      dot.append("circle")
          .attr("r", 5)
          .attr("fill", "#aaa")
          .attr("stroke", "#fff")
          .attr("stroke-width", 2);
    
      dot.append("text")
          .style("font", "10px sans-serif")
          .attr("text-anchor", "middle")
          .attr("y", -8);
    
      function moved(event) {
        event.preventDefault();
        const svgDim = svg.node().getBoundingClientRect()

        // console.log('svgDim', svgDim);
        // console.log('height', height);
        // console.log('width', width);
        
        const ym = (yScale.invert((event.layerY - 8 - 48) * (width/svgDim.width)));
        const xm = (xScale.invert((event.layerX - margin.left) * (width/svgDim.width)));
        const i1 = d3.bisectLeft(range, xm, 1);
        const i0 = i1 - 1;

        // console.log('ym', ym);
        // console.log('xm', xm);
        // console.log('i1', i1);
        // console.log('i0', i0);
        const i = xm - range[i0] > range[i1] - xm ? i1 : i0;
        const s = data.reduce((a, b) => Math.abs(a.values[i] - ym) < Math.abs(b.values[i] - ym) ? a : b);
        // path.attr("stroke", d => d === s ? color(d.key) : "#ccc").filter(d => d === s).raise();
        path.attr("stroke-opacity", d => d === s ? 1 : 0.25).attr("stroke-width", d => d === s ? 3 : 1.5).filter(d => d === s).raise();
        dot.attr("transform", `translate(${xScale(range[i])},${yScale(s.values[i])})`);
        dot.select("text").text(s.key + ',' + s.values[i]);
      }
    
      function entered() {
        // path.style("mix-blend-mode", null).attr("stroke", "#ddd");
        // path.style("mix-blend-mode", "overlay")
        // path.attr("stroke-opacity", 0.25);
        dot.attr("display", null);
      }
    
      function left() {
        // path.style("mix-blend-mode", "multiply").attr("stroke", function(d){ return color(d.key) });
        // path.style("mix-blend-mode", "normal")
        path.attr("stroke-opacity", 1)
          .attr("stroke-width", 1.5);
        dot.attr("display", "none");
      }
    }





    const xScale = d3.scaleLinear()
      .domain([0, n-1]) // input
      .range([margin.left, width - margin.right]); // output

    const yScale = d3.scaleLinear()
      // .domain([0, d3.max(data, d => d)]).nice()
      .domain([0, d3.max(data, d => d3.max(d.values))]).nice()
      .range([height - margin.bottom, margin.top]);
    

    // 7. d3's line generator
    const line = d3.line()
      .x((d, i) => { return xScale(i); }) // set the x values for the line generator
      .y(d => { return yScale(d); }) // set the y values for the line generator 
      // .curve(d3.curveMonotoneX) // apply smoothing to the line

    const svg = d3.select('#' + id).append('svg')
      .attr("preserveAspectRatio", "xMidYMid meet")
      .attr('viewBox', [0, 0, width, height]);
      // .attr('width', width)// - margin.left - margin.right)
      // .attr('height', height)// - margin.top - margin.bottom)
   
    const xAxis = g => g
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(xScale).ticks(width / 80).tickSizeOuter(0));

    const yAxis = g => g
      .attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(yScale))
      // .call(g => g.select(".domain").remove())
      // .call(g => g.select(".tick:last-of-type text").clone()
      //     .attr("x", 3)
      //     .attr("text-anchor", "start")
      //     .attr("font-weight", "bold")
      //     .text(data.y))

    svg.append('g')
      .call(xAxis);

    svg.append('g')
      .call(yAxis);

    const path = svg.append("g")
        .attr("fill", "none")
        // .attr("stroke", "steelblue")
        // .attr("stroke-width", 1.5)
        .attr("stroke-linejoin", "round")
        .attr("stroke-linecap", "round")
      .selectAll("path")
      .data(data)
      .join("path")
        .style("mix-blend-mode", "normal")
        .attr("stroke", d => { return (d.color  ? d.color : '#222') } )//color(d.key) })
        .attr("stroke-width", 1.5)
        .attr("d", d => line(d.values));

    const roundelSize = 20;
    const logo = svg.append('g')
      .selectAll('image')
      .data(data)
      .join("image")
        .attr('xlink:href', d => { return d.img }) 
        .attr('x', width - roundelSize)
        .attr('y', d => { return yScale(d.values[d.values.length - 1]) - roundelSize/2 })
        .attr('width', roundelSize)
        .attr('height', roundelSize);
        // .attr('transform', `translate(${width},0)`);

    svg.call(hover, path);
  };

  return <div id={id} prop={type}></div>
}

export default BarChart;