import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
// import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import HistoryIcon from '@mui/icons-material/History';
import { SwordCross } from 'mdi-material-ui'
import Container from "@mui/material/Container";
import { IPCTracker } from "./IPCTracker";
import { observer } from 'mobx-react-lite';
import gameStore from "../stores/gameStore";
import uiStore from "../stores/uiStore";
import { ProfileMenu } from "./ProfileMenu";
import { useViewport } from '../providers/ViewportProvider';

export const AppBarPlay = observer(({ pages }) => {
  const { width } = useViewport();
  const breakpoint = 900;

  return (
    <MuiAppBar className="AppBarPlay-appBar" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Container className="AppBarPlay-container">
        <Toolbar> 
          <Box>
            <IconButton
              onClick={() => {uiStore.toggleDialog('relations', !uiStore.dialog.open)}}
              color="inherit"
              disabled={gameStore.rules.hasOwnProperty('warPeace') && gameStore.rules.warPeace === true ? false : true}
            >
              <SwordCross />
            </IconButton>
            <IconButton
              onClick={() => {uiStore.toggleDrawer('objectives', !uiStore.drawers.objectives, width < breakpoint ? true : false)}}
              color="inherit"
              disabled={gameStore.objectives.length !== 0 ? false : true}
            >
              <WorkspacePremiumIcon />
            </IconButton>
          </Box>
          <Box className="AppBarPlay-toolBarContent">
            {gameStore.isPreprocessed && <IPCTracker className="IPCTracker" width="200px" height="100px"/>}
          </Box>
          <Box>
            <IconButton
              onClick={() => {uiStore.toggleDrawer('history', !uiStore.drawers.history, width < breakpoint ? true : false)}}
              color="inherit"
            >
              <HistoryIcon />
            </IconButton>
          </Box>
          <ProfileMenu pages={pages} />
        </Toolbar>
      </Container>
    </MuiAppBar>
  );
});
