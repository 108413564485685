import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// import EditIcon from '@mui/icons-material/Edit';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { visuallyHidden } from '@mui/utils';
import { useQuery } from "../hooks/useQuery";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from '../components/dialogs/ConfirmDialog';
import { QueryService } from '../services/query.service';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { GameSetupDialog } from '../components/dialogs/GameSetupDialog';
import { AuthService } from "../services/auth.service";
import gameStore from '../stores/gameStore';
import CircularProgress from "@mui/material/CircularProgress";

import config from '../config.json';

function descendingComparator(a, b, orderBy, secondOrderBy) {
  // split OrderBy in case it's a sub element
  const split = orderBy.split('.');

  var left = a;
  var right = b;
  for (let i of split) {
    left = left[i];
    right = right[i];
  }


  // DescendingComparator
  if (right < left) {
    return -1;
  }
  if (right > left) {
    return 1;
  }
  if (right === left && secondOrderBy) {
    if (b[secondOrderBy] < a[secondOrderBy]) {
      return -1;
    }
    if (b[secondOrderBy] > a[secondOrderBy]) {
      return 1;
    }
  }
  return 0;
}

function getComparator(order, orderBy, secondOrderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, secondOrderBy)
    : (a, b) => -descendingComparator(a, b, orderBy, secondOrderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const secondOrderBy = 'name';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    hideResponsive: false,
    label: 'Name',
  },
  {
    id: 'game.name',
    numeric: false,
    disablePadding: false,
    hideResponsive: false,
    label: 'Game',
  },
  {
    id: 'rules.name',
    numeric: false,
    disablePadding: false,
    hideResponsive: true,
    label: 'Rules',
  },
  {
    id: 'scenario.name',
    numeric: false,
    disablePadding: false,
    hideResponsive: false,
    label: 'Scenario',
  },
  {
    id: 'map.name',
    numeric: false,
    disablePadding: false,
    hideResponsive: true,
    label: 'Map',
  },
  {
    id: 'created',
    numeric: false,
    disablePadding: false,
    hideResponsive: true,
    label: 'Created',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    hideResponsive: false,
    label: 'State',
  },
  {
    id: 'pin',
    numeric: false,
    disablePadding: false,
    hideResponsive: false,
    label: 'PIN',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    hideResponsive: false,
    label: 'Actions',
  },
];

function EnhancedTableHead({ order, orderBy, rowCount, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ display: { xs: (headCell.hideResponsive ? 'none' : 'table-cell'), lg:'table-cell' } }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

Date.prototype.yyyymmdd = function(sep = '-') {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [this.getFullYear(),
          (mm>9 ? '' : '0') + mm,
          (dd>9 ? '' : '0') + dd
         ].join(sep);
};

export const GamePage = () => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [createOpen, setCreateOpen] = React.useState(false);
  const [id, setId] = React.useState('');
  const [state, setState] = React.useState('');
  const [rows, setRows] = React.useState([]);
  const [reload, setReload] = React.useState(1);
  var { data } = useQuery("get", config.BASE_URL + config.API_URL_PLAYS, reload);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const [loading, setLoading] = React.useState(true);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (data) {
      for (let record of data) {
        record['created'] = new Date(record.dates.created).yyyymmdd();
      }

      setRows(data);
      setLoading(false);
    }
  }, [data])

  const handleClickMenu = (event, id, state) => {
    setId(id);
    setState(state);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChangeState = (status) => {
    gameStore.emitPlayStatus({id, status})

    // edit game in the front-end
    let rows_patched = rows.map((object) => ({
      ...object,
    }));
    const existingItem = rows_patched.find(item => item._id === id);
    if (existingItem) {
      existingItem.status = status;
    }

    setRows(rows_patched);

    handleCloseMenu();
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteGame = () => {
    // remove game from server
    QueryService.deletePlay(id);

    // remove game from front-end
    setRows(rows.filter((v) => v._id !== id));
  }

  const joinGame = (pin) => {
    setLoading(true);
    
    AuthService.join(pin).then(
      (data) => {
        // setLoading(false);     // not needed as we move away to a new page which is sometimes taking more than expected
        gameStore.setPlay(data?.play?.id);
        navigate("/play")
      },
      (error) => {
        console.error('error', error.response);
        setLoading(false);

        // setMessage(error.Message);
        
        // const err = error.response?.data?.errors;
        // if (err) {
        //   setErrors(err);
        // }
        // else {
        //   const resMessage =
        //     (error.response && 
        //       error.response.data && 
        //       error.response.data.message) ||
        //     "Oops something went wrong!"; 

        //   setMessage(resMessage);
        //   // setMessage(JSON.stringify(error.message));
        // }
      }
    );
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  if (loading) {
    return (
      <Box className="centerContainer">
        <CircularProgress disableShrink color="secondary" />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 'calc(100% - 16px)'}}>
      <Paper sx={{ width: '100%', mb: 2, position: "relative" }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy, secondOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.name}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell>{row.game.name}</TableCell>
                      <TableCell sx={{ display: { xs: 'none', lg:'table-cell' } }}>{row.rules.name}</TableCell>
                      <TableCell>{row.scenario.name}</TableCell>
                      <TableCell sx={{ display: { xs: 'none', lg:'table-cell' } }}>{row.map.name}</TableCell>
                      <TableCell sx={{ display: { xs: 'none', lg:'table-cell' } }}>{row.created}</TableCell>
                      <TableCell onClick={(event) => {handleClickMenu(event, row._id, row.status)}} sx={{ cursor: 'pointer', width: 90 }}>
                        {row.status}
                        <IconButton size="small" disableRipple sx={{
                          margin: "-20px 0px",
                          padding: 0,
                        }}>
                          {openMenu && row._id === id
                            ? <ArrowDropUpIcon />
                            : <ArrowDropDownIcon />
                          }
                        </IconButton>
                        {
                        //   <Select
                        //   labelId="demo-simple-select-label"
                        //   id="demo-simple-select"
                        //   value={row.satus}
                        //   // label="Age"
                        //   size="small"
                        //   // onChange={handleChange}
                        // >
                        //   <MenuItem value='playing'>Playing</MenuItem>
                        //   <MenuItem value='paused'>Paused</MenuItem>
                        //   <MenuItem value='ready'>Ready</MenuItem>
                        //   <MenuItem value='stopped'>Stopped</MenuItem>
                        // </Select>
                        }
                      </TableCell>
                      <TableCell>{row.pin}</TableCell>
                      <TableCell padding="none" align="right" width="110px">
                        <IconButton size="small" disabled={row.status !== 'Playing'} onClick={() => {joinGame(row.pin)}}>
                          <VisibilityOutlinedIcon />
                        </IconButton>
                        <IconButton size="small" disabled={true /*row.status !== 'Stopped' && row.status !== 'Ready'*/} onClick={() => {setCreateOpen(true)}}>
                          <EditOutlinedIcon />
                        </IconButton>
                        <IconButton size="small" onClick={() => {setId(row._id); setConfirmOpen(true)}} sx={{
                          mr: '6px',
                        }}>
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination sx={{
          align: "center",
          textAlign: "center",
          paddingBottom: "20px"
        }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Fab color="primary" aria-label="add" onClick={() => {setCreateOpen(true)}} sx={{
          position: "absolute",
          backgroundColor: "secondary.main",
          bottom: -28,
          right: 16,
          // marginTop: "-56px",
          // marginLeft: "10px"
        }}>
          <AddIcon />
        </Fab>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          { state !== 'Playing' && <MenuItem onClick={() => {handleChangeState('Playing')}} sx={{fontSize: '0.875rem'}}>Play</MenuItem>}
          { state !== 'Stopped' && <MenuItem onClick={() => {handleChangeState('Stopped')}} sx={{fontSize: '0.875rem'}}>Stop</MenuItem>}
          { state !== 'Paused' && <MenuItem onClick={() => {handleChangeState('Paused')}} sx={{fontSize: '0.875rem'}}>Pause</MenuItem>}
        </Menu>
      </Paper>
      <ConfirmDialog
        title="Delete Game?"
        open={confirmOpen}
        handleClose={() => {setConfirmOpen(false)}}
        handleConfirm={deleteGame}
      >
        Are you sure you want to delete this game?
      </ConfirmDialog>
      <GameSetupDialog
        open={createOpen}
        handleClose={() => {setCreateOpen(false)}}
        triggerReload={() => {setReload(reload + 1)}}
      >
      </GameSetupDialog>
    </Box>

  );
}
