import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorPage } from '../pages/Error';
import { observer } from 'mobx-react-lite';
import uiStore from "../stores/uiStore";

export const ErrorHandler = observer(({ children }) => {
  const location = useLocation();
  console.log('ErrorHandler', location);
  console.log('ErrorHandler', location.state);

  useEffect(() => {
    if (uiStore.redirect)
      location.state = uiStore.redirect.state;
      uiStore.setRedirect('');
  }, [uiStore.redirect])

  switch (location.state) {
    case 400:
      return <ErrorPage error={location.state}/>;
    case 403:
      return <ErrorPage error={location.state}/>;
    case 404:
      return <ErrorPage error={location.state}/>;
    case 500:
      return <ErrorPage error={location.state}/>;
      
    // ... cases for other types of errors
      
    default:
      return children
  }
});