import axios from "axios";

import config from '../config.json';

const API_URL = config.AUTH_BASE_URL;

const login = (email, password) => {
  return axios
    .post(API_URL + "/authenticate", {
      email,
      password,
    })
    .then((response) => {
      // if (response.data.name) {
      //   localStorage.setItem("user", JSON.stringify(response.data));
      // }

      // console.log('login', response);
      // let cookie = response.headers.cookie;
      // console.log('get cookie', cookie);
      
      // console.log(response.config.headers.Cookie)

      return response.data;
    });
};

const logout = () => {
  // localStorage.removeItem("user");
  return axios
    .post(API_URL + "/revoke-token")
    .then((response) => {
      return response.data;
    });
};

function refreshToken() {
  return axios
    .post(API_URL + "/refresh-token")
    .then(user => {
        // publish user to subscribers and start timer to refresh token
        // userSubject.next(user);
        // startRefreshTokenTimer();
        return user;
    });
}

const signup = (name, email, password) => {     
  return axios
    .post(API_URL + "/register", {
      name,
      email,
      password,
    })
    .then((response) => {
      return response.data;
    });
};

const verifyEmail = (token) => {
  return axios
    .post(API_URL + "/validate-email", {
      token,
    })
    .then((response) => {
      return response.data;
    });
};

const resendVerificationEmail = (email) => {
  return axios
    .post(API_URL + "/resend-email", {
      email,
    })
    .then((response) => {
      console.log('response', response);
      return response.data;
    });
};

const forgotPassword = (email) => {
  return axios
    .post(API_URL + "/forgot-password", {
      email,
    })
    .then((response) => {
      return response.data;
    });
}

const validateResetToken = (token) => {
  return axios
    .post(API_URL + "/validate-reset-token", {
      token,
    })
    .then((response) => {
      return response.data;
    });
};

const resetPassword = (token, password, confirmPassword) => {
  return axios
    .post(API_URL + "/reset-password", {
      token,
      password,
      confirmPassword,
    })
    .then((response) => {
      return response.data;
    });
};

const join = (pin) => {
  return axios
    .post(API_URL + "/join", {
      pin,
    })
    .then((response) => {
      // if (response.data.game) {
      //   localStorage.setItem("game", JSON.stringify(response.data.game));
      // }

      return response.data;
    });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};


// helper functions

let refreshTokenTimeout;

// function startRefreshTokenTimer() {
//   // parse json object from base64 encoded jwt token
//   const jwtToken = JSON.parse(atob(userSubject.value.jwtToken.split('.')[1]));

//   // set a timeout to refresh the token a minute before it expires
//   const expires = new Date(jwtToken.exp * 1000);
//   const timeout = expires.getTime() - Date.now() - (60 * 1000);
//   refreshTokenTimeout = setTimeout(refreshToken, timeout);
// }

// function stopRefreshTokenTimer() {
//   clearTimeout(refreshTokenTimeout);
// }

export const AuthService = {
  login,
  logout,
  refreshToken,
  signup,  
  verifyEmail,
  resendVerificationEmail,
  forgotPassword,
  validateResetToken,
  resetPassword,
  join,
  getCurrentUser,
}