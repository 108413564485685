import { Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/material/styles';
import PropTypes from 'prop-types';
import ConfirmDialog from './ConfirmDialog';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
// import withMobileDialog from '@mui/material/withMobileDialog';

// NO NEED TO BE A observer!!!??
export const DeclarationOfWarDialog = ({ open, attacker, defenders, territory, roundels, handleClose, handleConfirm }) => {

  console.log('DeclarationOfWarDialog', defenders)
  if (attacker == null || defenders == null || territory == null || roundels == null || handleClose == null || handleConfirm == null) {
    return;
  }

  return (
    <ConfirmDialog
      open={open}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      PaperProps={{
        sx: {
          backgroundColor: "background.dialog",
        }
      }}
      title='Declaration of War'
      label='declaration-of-war-dialog'
      
    >
      <Box>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }} >
          <img 
            className='roundelSmall paddingRight6'
            src={roundels[attacker]} 
            alt={'roundel ' + attacker}
          />
          is not yet at war with 
          <img 
            className={defenders.length > 1 ? 'roundelSmall paddingLeft6 paddingRight6' : 'roundelSmall paddingLeft6'}
            src={roundels[defenders[0]]} 
            alt={'roundel ' + defenders[0]}
          />
          {defenders.length > 1 ? 'and its allies' : ''}
          .
        </Box>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }} >
          Do you want to declare war on
          {defenders?.map((defender, i, {length}) => (
            <Fragment>
              <img 
                className={i === 0 && length > 2 ? 'roundelSmall paddingLeft6' : 'roundelSmall paddingLeft6 paddingRight6'}
                src={roundels[defender]} 
                alt={'roundel ' + defender}
              />
              {i + 2 === length ? 'and'
              : i + 1 < length ? ',' : ''}
            </Fragment>
          ))}
          to attack:
        </Box>
        <Box sx={{
          paddingTop: '10px',
          textAlign: 'center'
        }}>
          "{territory}"
        </Box>
      </Box>
    </ConfirmDialog>
  )
};