import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../services/auth.service";

export const PasswordResetPage = () => {
  const params = useParams();
  console.log('token', params.token);
  
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const handleContinue = (event) => {
    navigate("/login", { replace: true });
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    setLoading(true);
    setErrors({password: "", confirmPassword: ""});
    setMessage("");

    AuthService.resetPassword(params.token, password, confirmPassword).then(
      () => {
        setSuccess(true);
        setLoading(false);
        console.log('success');
      },
      (error) => {
        console.log('error', error.response);
        setLoading(false);
        
        const err = error.response.data.errors;
        if (err) {
          setErrors(err);
        }
        else {
          const resMessage =
            (error.response && 
              error.response.data && 
              error.response.data.message) ||
            "Oops something went wrong!"; 

          setMessage(resMessage);
        }
      }
    );
  };

  return (
    <Container component="main" maxWidth="xs" 
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <KeyOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Password Reset
        </Typography>
        {message && <p className="error-message">{message}</p>}
        {success && <p>Password reset successful</p>}
        {!success &&
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="password"
              onChange={event => setPassword(event.target.value)}
              helperText={errors.password}
              error={errors.password ? true : false}
            />
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              name="confirm-password"
              label="Confirm Password"
              type="password"
              id="confirm-password"
              onChange={event => setConfirmPassword(event.target.value)}
              helperText={errors.confirmPassword}
              error={errors.confirmPassword ? true : false}
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}
              // loadingPosition="end"
            >
              Reset password
            </LoadingButton>
            <Grid container>
              <Grid item>
                <RouterLink to="/login">
                  <Link href="#" variant="body2">
                    {"Remember your password? Login"}
                  </Link>
                </RouterLink>
              </Grid>
            </Grid>
          </Box>
        }
        {success &&
          <Box>
            <Button
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2 }}
              onClick={handleContinue}
            >
              Continue
            </Button>
          </Box>
        }
      </Box>
    </Container>
  );
};
