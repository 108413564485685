import gameStore from '../stores/gameStore';
import uiStore from '../stores/uiStore';

const test = false;

const _is_rule_active = rule => {
    const rules = gameStore.data.rules.rules;
  
    if (rules.hasOwnProperty(rule) && rules[rule] === true) {
      return true;
    }
  
    return false;
  };
  
  // const _is_neutral = nation => {
  //   if (gameStore.players.teams.hasOwnProperty('neutrals') && gameStore.players.teams.neutrals.includes(nation)) {
  //     return true;
  //   }
  
  //   return false;
  // };
  
  // const _get_team = nation => {
  //   for (let team in gameStore.players.teams) {
  //     if (team.includes(nation)) {
  //       return team;
  //     }
  //   }
  
  //   return '';
  // }
  
  const _is_aligned_territory = (territory, alignedNation) => {
    if (territory.hasOwnProperty('aligned') && territory.own === territory.org) {
      if (territory.aligned === alignedNation 
        || isOnSameTeam(territory.aligned, alignedNation)
        || territory.aligned === gameStore.getTeam(alignedNation)) {
        return true;
      }
    }
    
    return false;
  };
    
const captureTerritory = (index, attacker) => {
  // const gameStore = stores.gameStore;
  const territory = gameStore.territories[index];

  const team = {
    attacker: gameStore.getTeam(attacker),
    org: gameStore.getTeam(territory.org),
    own: gameStore.getTeam(territory.own),
  };

  const parentOwner = gameStore.getParentNation(territory.org, 'economical');

  logger('territory:', territory.name);
  logger('IPC:', territory.ipc);
  logger('index:', index);
  
  // You cannot capture a territory that belongs to your side
  if (team.attacker === team.own) {
    logger(attacker + ' is not allowed to attack its own team ' + index);
    return;
  }

  // In case of a subEconomy check if the territoy is in the influantial sphere of a vassal (despite capital status)
  if (gameStore.players.subEconomy.hasOwnProperty(attacker)) {
    let vassal = gameStore.players.subEconomy[attacker];
    console.log('VASSAL', attacker, 'has vassal', vassal);
    console.log('VASSAL territory:', territory);
    console.log('VASSAL capitals', gameStore.cities.capitals);
    if (territory.hasOwnProperty('sub') && territory.sub.includes(vassal)) {
      attacker = vassal;
      console.log('VASSAL', vassal, gameStore.cities.capitals[vassal])
    }
  }

  // If a country with a limited presence is attacking check if it is allowed to enter
  if (gameStore.players.hasOwnProperty('limitedPresence') && gameStore.players.limitedPresence.hasOwnProperty(attacker)) {
    let limitedPresence = gameStore.players.limitedPresence[attacker].slice();
    if (!limitedPresence.includes(parseInt(index))) {
      logger(attacker + ' is not allowed to enter ' + index);
      return;
    }
  }

  // If a nation with limited attacking nations is attacked, return if the attacker is not in the allow list
  if (territory.own in gameStore.players.limitedAttackingNations) {
    if (!gameStore.players.limitedAttackingNations[territory.own].includes(attacker)) {
      logger('gameStore.players.limitedAttackingNations', gameStore.players.limitedAttackingNations)
      logger(territory.own + ' is not allowed to be attacked by ' + attacker);
      return;
    }
  }

  console.error('LIBERATED', parentOwner, gameStore.cities.capitals);

  // Liberation instead of capture
  if (team.attacker === team.org) {
    // if capital is still in orginal owners control
    if (gameStore.cities.capitals[parentOwner] === true) {
      logger('Liberated territory ' + territory.org);
      attacker = territory.org;
    }
    // else check if we liberate orginal owners capital
    else if (territory.cap === true) {
      logger('Liberated capital for ' + territory.org);
      attacker = territory.org;
    }
    // else check if we liberated our own vassal state's territory (despite capital status)
    else if (attacker === parentOwner) {
      logger('Liberated territory ' + territory.name + ' for or own vassal ' + territory.org);
      attacker = territory.org;
    }
  }

  // Ensure contested state in between captures
  if (_is_rule_active('contested')) {
    if (territory.own !== 'CON' && !_is_aligned_territory(territory, attacker)) {
      attacker = 'CON';
    }
  }

  changeOwner(index, attacker);
}

const nationalObjectives = () => {
  let bonusIncome = {};
  
  for (let index = 0; index < gameStore.data.rules.objectives.length; index++) {
    let objective = gameStore.data.rules.objectives[index];

    if (objective.name === "British Commonwealth")
      console.error(objective)
    // logger('objective', objective);

    if (objective.type === 'calculated') {
      if (objective.hasOwnProperty('trigger')) {

        if (objective.trigger.hasOwnProperty('peace')) {
          let players = objective.trigger.peace.players;

          if (objective.trigger.peace.type === 'or') {
            let count = 0;
            // for (let player in players) {
            for (let i = 0; i < players.length; i++) {
              if (gameStore.players.atWar[objective.player].includes(players[i])) {
                count++;
              }
            }

            if (count === players.length) {
              logger('objectives peace condition not met', objective);
              gameStore.objectives[index].ipc = 0;
              continue;
            }
          }
          else if (objective.trigger.peace.type === 'and' || players.length === 1) {
            let peace = true;
            for (let i = 0; i < players.length; i++) {
              if (gameStore.players.atWar[objective.player].includes(players[i])) {
                peace = false;
                break
              }
            }

            if (!peace) {
              logger('objectives peace condition not met', objective);
              gameStore.objectives[index].ipc = 0;
              continue;
            }
          }
        }

        if (objective.trigger.hasOwnProperty('war')) {
          let players = objective.trigger.war.players;

          if (objective.trigger.war.type === 'or') {
            let count = 0;
            // for (let player in players) {
            for (let i = 0; i < players.length; i++) {
              if (!gameStore.players.atWar[objective.player].includes(players[i])) {
                count++;
              }
            }

            if (count === players.length) {
              logger('objectives war condition not met', objective);
              gameStore.objectives[index].ipc = 0;
              continue;
            }
          }
          else if (objective.trigger.war.type === 'and' || players.length === 1) {
            let war = true;
            for (let i = 0; i < players.length; i++) {
              // logger('gameStore.players.atWar[objective.player]', gameStore.players.atWar[objective.player]);
              if (!gameStore.players.atWar[objective.player].includes(players[i])) {
                war = false;
              }
            }

            if (!war) {
              logger('objectives war condition not met', objective);
              gameStore.objectives[index].ipc = 0;
              continue;
            }
          }
        }


        let ownCount = 0;
        if (objective.trigger.hasOwnProperty('own')) {
          let territories = objective.trigger.own.territories;
          let owners = objective.trigger.own.owners;
          
          for (let i = 0; i < territories.length; i++) {
            if (owners.includes(gameStore.territories[territories[i]].own)) {
              ownCount++;
            }
          }
        }

        if (objective.trigger.hasOwnProperty('own2')) {
          let territories = objective.trigger.own2.territories;
          let owners = objective.trigger.own2.owners;
          
          for (let i = 0; i < territories.length; i++) {
            if (owners.includes(gameStore.territories[territories[i]].own)) {
              ownCount++;
            }
          }
        }

        logger('ownCount', ownCount);

        let bonus = 0;
        if (objective.bonus.type === 'fixed' 
            || (Number.isInteger(objective.bonus.type) && ownCount >= objective.bonus.type)) {
  
          if (objective.bonus.special === 'once' && gameStore.objectives[index].triggered === true) {
            gameStore.objectives[index].ipc = 0;
            continue;
          }

          bonus = objective.bonus.value;
          
          if (objective.bonus.special === 'once') {
            gameStore.objectives[index].triggered = true;
          }
        }
        else if (objective.bonus.type === 'per') {
          bonus = ownCount * objective.bonus.value;
        }

        let player = objective.player;
        if (objective.bonus.hasOwnProperty('to')) {
          player = objective.bonus.to;
        }

        if (!bonusIncome.hasOwnProperty(player)) {
          bonusIncome[player] = bonus;
        }
        else {
          bonusIncome[player] += bonus;
        }
        gameStore.objectives[index].ipc = bonus;
      }
      else {
        console.error('calculated objective has no trigger', objective);
      }
    }
    else if (objective.type === 'manual') {
      if (gameStore.objectives[index].status === true) {
        if (objective.bonus.type === 'fixed') {
          let player = objective.player;
          if (!bonusIncome.hasOwnProperty(player)) {
            bonusIncome[player] = objective.bonus.value;
          }
          else {
            bonusIncome[player] += objective.bonus.value;
          }
          gameStore.objectives[index].ipc = objective.bonus.value;
        }
      }
    }
    else {
      console.error('unknown objective type', objective.type);
    }
  }

  return bonusIncome;
}

const getVictoryConditions = () => {
    let axis = 0,
      allies = 0;
  // const gameStore = stores.gameStore;

  for (let c in gameStore.cities.victoryCities) {
    let city = gameStore.cities.victoryCities[c];

    if (gameStore.getTeam(city.own) === 'axis') {
      axis++;
    }
    else if (gameStore.getTeam(city.own) === 'allies') {
      allies++;
    }
  }

  gameStore.setVictoryCities({
    axis: axis,
    allies: allies,
  });
}

const changeOwner = (index, owner, showMessage = true, storeEvent = true) => {
  // const gameStore = stores.gameStore;
  let territory = gameStore.territories[index];
  let liberatedCapital = '';

  logger('changeOwner ' + territory.name + ' to ' + owner);

  if (territory.hasOwnProperty('vc') && territory.vc !== '') {
    
    let message = territory.vc;

    if (territory.cap === true) {
      logger('owner ' + owner);
      if (_is_rule_active('contested') && owner === 'CON') {
        message += ' has been contested.';
      }
      else if (gameStore.getTeam(territory.org) !== gameStore.getTeam(owner)) {
        message += ' has been captured.';
        gameStore.captureCapital(territory.org);
      }
      else {
        message += ' has been liberated.';
        gameStore.liberateCapital(territory.org);
        liberatedCapital = territory.org;
      }
    }
    else {
      message += ' has been taken.';
    }

    if (showMessage === true) {
      uiStore.showSnackbar(message, 'success');
    }
    gameStore.changeOwnerCity(territory.vc, owner);

    getVictoryConditions();
  }

  // Store Event when set to true
  if (storeEvent === true) {
    if (gameStore.getTeam(territory.org) === gameStore.getTeam(owner) && gameStore.cities.capitals[territory.org] === true) {
      if (gameStore.getTeam(territory.own) !== gameStore.getTeam(owner) ) {
        gameStore.changeOwnerTerritoryLiberate(index, owner, territory.own)
      } 
      else {
        gameStore.changeOwnerTerritoryRevert(index, owner, territory.own)
      }
    }
    else {
      if (owner === 'CON') {
        gameStore.changeOwnerTerritoryContest(index, owner, territory.own);
      }
      else if (_is_aligned_territory(territory, owner)) {
        if (territory.aligned === gameStore.getTeam(owner)) {
          gameStore.changeOwnerTerritoryMobilized(index, owner, territory.own);
        }
        else {
          gameStore.changeOwnerTerritoryMobilized(index, territory.aligned, territory.own);
        }
      }
      else {
        gameStore.changeOwnerTerritoryCapture(index, owner, territory.own);
      }
    }
  }
  else {
    gameStore.changeOwnerTerritoryDirect(index, owner);
  }

  // When the capital is liberated, revert all temporary team owned territories back to their original owner
  if (liberatedCapital !== '') {
    logger('liberation', liberatedCapital);
    Object.keys(gameStore.territories).forEach((key) => {
      let territory = gameStore.territories[key];

      if (territory.org === liberatedCapital && territory.own !== liberatedCapital && gameStore.getTeam(territory.own) === gameStore.getTeam(liberatedCapital)) {
        logger('reverting ' + territory.name + ' to ' + owner);
        changeOwner(key, owner, showMessage, storeEvent);
      }

      // Check for vassals without there own subEconomy
      let vassals = gameStore.getVassals(liberatedCapital, 'economical');
      // if (vassals.length > 0) {
      for (let vassal of vassals) {
        if (territory.org === vassal && territory.own !== vassal && gameStore.getTeam(territory.own) === gameStore.getTeam(liberatedCapital)) {
          logger('reverting ' + territory.name + ' to ' + vassal);
          changeOwner(key, vassal, showMessage, storeEvent);
        }
      }
    })
  }
}

const isAtWar = (nationA, nationB) => {
  nationB = gameStore.getParentNation(nationB, 'political');

  if (gameStore.players.atWar[nationA].includes(nationB)
    || (gameStore.data.rules.rules.hasOwnProperty('contested') && gameStore.data.rules.rules.contested === true)) {
    return true;
  }
  return false;
}

const isOnSameTeam = (nationA, nationB) => {
  if (gameStore.getTeam(nationA) === gameStore.getTeam(nationB)) {
    return true;
  }
  return false;
}

export {captureTerritory, nationalObjectives, getVictoryConditions, changeOwner, isAtWar, isOnSameTeam};

function logger(msg, ...data) {
  if (test) {
    if (data.length > 0) console.log(msg, data);
    else console.log(msg);
  }
}