import { createContext, useContext, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { observer } from 'mobx-react-lite';
import uiStore from "../stores/uiStore";

const AuthContext = createContext();

export const AuthProvider = observer(({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  // const { setPlayID } = usePlay()
  const navigate = useNavigate();
  // const loggedIn = uiStore.userState.loggedIn;

  // useEffect(() => {
  //   if (!uiStore.userState.loggedIn && user != null) {
  //     logout();
  //   } 
  // }, [uiStore.userState.loggedIn])

  // useEffect(() => {
  //   if (user) {
  //     uiStore.setUserState('loggedIn', true);
  //   }
  // }, [user])

  useEffect(() => {
    if (user) {
      uiStore.setUserState('loggedIn', true);
    }
    else {
      uiStore.setUserState('loggedIn', false);
    }
  }, [user])

  useEffect(() => {
    console.log('TESTING LOGGED OUT', user, uiStore.userState.loggedIn);
      
    if (uiStore.userState.loggedIn === false) {
      console.log('TESTING LOGGED OUT');
      if (user) {
        console.log('LOGGING OUT');
        logout();
      }
    }
  }, [uiStore.userState.loggedIn])

  // call this function when you want to authenticate the user
  const login = async (data) => {
    console.log('login validation', data);
    setUser(data);
    // uiStore.setUserState('loggedIn', true);
    navigate("/game", { replace: true });
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    console.log('AuthProvider Logout setPlayID', null);
    // setPlayID(null);
    // uiStore.setUserState('loggedIn', false);
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [user, login, logout]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
});

export const useAuth = () => {
  return useContext(AuthContext);
};