import Box from '@mui/material/Box';

export const IPCValue = ({ nation, income, roundel, currentPlayer, active, handleOnClick }) => {

  if (nation === undefined || income === undefined || roundel === undefined || currentPlayer === undefined || active === undefined || handleOnClick === undefined) {
    console.error('IPCValue missing props', nation, income, roundel, currentPlayer, active, handleOnClick);
    return;
  }

  // console.log('income', nation, income);
  if (typeof income === 'number') {
    return (
      <div className={nation === currentPlayer ? "IPCValue-container IPCValue-activeContainer" : "IPCValue-container"}>
        <span className="helper"></span>
        <img 
          src={roundel}
          alt={'roundel ' + nation}
          className={nation === currentPlayer ? "IPCValue-roundel IPCValue-activePlayer" : "IPCValue-roundel"}
          onClick={() => {handleOnClick(nation)}}
        />
        <div className={active === false ? "IPCValue-ipc IPCValue-noCapital stroke" : "IPCValue-ipc stroke"} stroketext={income}>
          {income}  
        </div>
      </div>
    )
  } 
  else if (income.length === 2) {
    return (
      <div className={nation === currentPlayer ? "IPCValue-container IPCValue-activeContainer" : "IPCValue-container"}>
        <span className="helper"></span>
        <img 
          src={roundel}
          alt={'roundel ' + nation}
          className={nation === currentPlayer ? "IPCValue-roundel IPCValue-activePlayer" : "IPCValue-roundel"}
          onClick={() => {handleOnClick(nation)}}
        />
        <div className="IPCValue-ipc">
          <div className={active[0] === false ? "IPCValue-ipcLeft IPCValue-noCapital stroke" : "IPCValue-ipcLeft stroke"}  stroketext={income[0]}>
            {income[0]}
          </div>
          <div className={active[1] === false ? "IPCValue-ipcRight IPCValue-noCapital stroke" : "IPCValue-ipcRight stroke"}  stroketext={income[1]}>
            {income[1]}  
          </div>
        </div>
      </div>
    )
  }
};