import { MapContainer, TileLayer, ImageOverlay } from "react-leaflet";
import { CRS } from "leaflet";
import Box from "@mui/material/Box";
import gameStore from '../stores/gameStore';
import uiStore from '../stores/uiStore';
import { observer } from 'mobx-react-lite';
import { captureTerritory, isAtWar, isOnSameTeam } from '../logic/gameRules';

// import 'leaflet/dist/leaflet.css'
 
const ERROR_IMG = "/img/logo.png",
      DEFAULT_ROUNDEL = "/img/roundels/contested.svg",
      boundsSpace = 10;

// let tileLayer.wms_headers = (url, options) => new L.TileLayer.WMS_Headers(url, options);

export const TileMap = observer((props) => {
  const map = gameStore.data.map;
  const territories = gameStore.territories;
  const players = gameStore.players;
  
  console.log('render: TileMap');

  if (!props.type) {
    props.type = "game";
  }

  const handleClick = (key) => { 
    switch(props.type) {
      case "game":
        const player = gameStore.players.current;
        const owner = gameStore.territories[key].own
        if (isAtWar(player, owner)) {
          captureTerritory(key, player);
        }
        else if (!isOnSameTeam(player, owner)) {
          console.log('not at war with ', owner, ' yet!');
          // uiStore.showSnackbar(gameStore.players.current + ' is not at war with ' + gameStore.territories[key].own, 'success');
          uiStore.showDeclarationOfWarDialog(gameStore.getParentNation(player, 'political'), 
            gameStore.getParentNation(owner, 'political'), 
            gameStore.territories[key].name, 
            () => {captureTerritory(key, player)});
        }
        break;
      case "editor":
        break;
      default:
        break;
    };
  };
  
  if (map.hasOwnProperty('_id')) {
    return ( 
      <Box sx={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "background.contentFrame",
        }}>
        <MapContainer 
          crs={CRS.Simple}
          center={map.center.slice()}
          zoom={map.zoom}
          zoomControl={false}
          attributionControl={false}
          maxBounds={[[map.bounds[0][0] + boundsSpace,map.bounds[0][1] - boundsSpace],
            [map.bounds[1][0] - boundsSpace,map.bounds[1][1] + boundsSpace]]}
          wheelPxPerZoomLevel={200}
          maxBoundsViscosity={0}
        >
          <TileLayer
            url={map.tilesURL}
            minZoom={map.minZoom}
            maxZoom={map.maxZoom}
            maxNativeZoom={map.maxNativeZoom}
            bounds={map.bounds}
            errorTileUrl={ERROR_IMG}
          />
          {Object.keys(territories).map((key) => (
            territories[key].x !== "" ?
              <ImageOverlay
                key={territories[key].name}
                url={players.roundels.hasOwnProperty(territories[key].own) ? players.roundels[territories[key].own] : DEFAULT_ROUNDEL}
                interactive={true}
                bounds={[[territories[key].y - territories[key].size / 2, territories[key].x - territories[key].size / 2],[territories[key].y + territories[key].size / 2, territories[key].x + territories[key].size / 2]]}
                eventHandlers={{ click: () => { handleClick(key) } }}
              />
            :
              null
          ))}
        </MapContainer>
      </Box>
    )
  }
  else 
    return (
      <Box sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        /* bring your own prefixes */
        transform: "translate(-50%, -50%)",
        textAlign: "center",
      }}>
        ERROR: Map cannot be loaded
      </Box> 
    )
})