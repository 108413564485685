import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useQuery } from "../hooks/useQuery";

const test = false;

const StyledList = styled(List)({
  '& .MuiListItem-root': {
    marginTop: 0,
    marginBottom: 5,
  },
  '& .MuiListItemIcon-root': {
    // color: "#fff",
    minWidth: 0,
    marginRight: 200,
  },
  '& .MuiSvgIcon-root': {
    // color: "rgba(255, 255, 255, 0.54)"
  },
  '& .MuiListItemText-primary': {
    // color: "#fff"
  },
  '& .MuiListItemText-secondary': {
    // color: "#fff"
  }
});

export default function ItemPicker(props) {
  logger('props', props);

  const [anchorEl, setAnchorEl] = useState(null);
  const [description, setDescription] = useState("test");

  const handlePopoverOpen = (event, index) => {
    // logger('data', data);
    // logger('index', index);
    // logger('game', data[index].description);
    let popup = data[index]?.description ? data[index].description : "No description available";
    setDescription(popup);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const { data } = useQuery("get", props.url);

  useEffect(() => {
    logger('ItemPicker', data);
    if (data && data instanceof Array) {
      data.sort();
    }
    // logger(games);
  }, [data])

  return (
    <Box>
      <StyledList sx={{ width: 350, maxHeight: 450 }} cols={1} rowHeight={50}>
        {data && data instanceof Array && data.map((item, index) => (
          <ListItem key={item._id} className="hoverOpacity" onClick={() => {props.onClick(item)}} 
            sx={{ backgroundColor: item._id === props.selectedID ? "secondary.main" : "primary.mainAlpha20", cursor: "pointer" }}
            secondaryAction={
              <IconButton edge="end" aria-label="delete"
                onMouseEnter={(event) => {handlePopoverOpen(event, index)}}
                onMouseLeave={handlePopoverClose}
              >
                <InfoIcon />
              </IconButton>
            }
          >
            <ListItemText
              primary={item.name}
              secondary={item.author ? item.author : 'unknown author'}
            />
          </ListItem>
        ))}
        {data && !(data instanceof Array) &&
          <ListItem key={data._id} className="hoverOpacity" onClick={() => {props.onClick(data)}} 
            sx={{ backgroundColor: data._id === props.selectedID ? "secondary.main" : "primary.mainAlpha20", cursor: "pointer" }}
            secondaryAction={
              <IconButton edge="end" aria-label="delete"
                onMouseEnter={(event) => {handlePopoverOpen(event, 0)}}
                onMouseLeave={handlePopoverClose}
              >
                <InfoIcon />
              </IconButton>
            }
          >
            <ListItemText
              primary={data.name}
              secondary={data.author ? data.author : 'unknown author'}
            />
          </ListItem>
        }
      </StyledList>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{description}</Typography>
      </Popover>
    </Box>
  );
}

function logger(msg, ...data) {
  if (test) {
    if (data.length > 0) console.log(msg, data);
    else console.log(msg);
  }
}