import { Routes, Route } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { JoinPage } from "./pages/Join";
import { LoginPage } from "./pages/Login";
import { PasswordResetPage } from "./pages/PasswordReset";
import { PasswordForgottenPage } from "./pages/PasswordForgotten";
import { ProfilePage } from "./pages/Profile";
import { SettingsPage } from "./pages/Settings";
import { SignUpPage } from "./pages/SignUp";
import { VerifyEmailPage } from "./pages/VerifyEmail";
import { ResendEmailVerificationPage } from "./pages/ResendEmailVerification";
import { PlayLayout } from "./components/PlayLayout";
import { HomeLayout } from "./components/HomeLayout";
import { GamePage } from "./pages/Game";
// import { GameSetupPage } from "./pages/GameSetup";
import { NotFoundPage } from "./pages/NotFound";
import { ErrorHandler } from "./components/ErrorHandler";
import { SnackbarNotification } from "./components/SnackbarNotification";
import { StyledEngineProvider } from "@mui/material/styles";
import { PlayPage } from "./pages/Play";
import { useAuth } from "./hooks/useAuth";
import uiStore from "./stores/uiStore";
import { ViewportProvider } from "./providers/ViewportProvider";
import "./App.css";

export const App = observer(() => {
  const { user } = useAuth();

  return (
    <StyledEngineProvider injectFirst>
      <ViewportProvider>
        <ErrorHandler>
          <Routes>
            <Route element={<HomeLayout />}>
              <Route path="/" element={<JoinPage />} />
              <Route path='*' element={<NotFoundPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/sign-up" element={<SignUpPage />} />
              <Route path="/forgot-password" element={<PasswordForgottenPage />} />
              <Route path="/reset-password/:token" element={<PasswordResetPage />} />
              <Route path="/verify-email/:token" element={<VerifyEmailPage />} />
              <Route path="/resend-email/" element={<ResendEmailVerificationPage />} />
            </Route>
            { user && user.roles && user.roles.indexOf('viewer') != -1 &&
              <Route path="/play" element={<PlayLayout user={user}/>}>
                <Route path="" element={<PlayPage />} />
              </Route>
            }
            { user && user.roles && user.roles.indexOf('creator') != -1 &&
              <Route path="/game" element={<HomeLayout />}>
                <Route path="" element={<GamePage />} />
                {/*<Route path="create" element={<GameSetupPage />} />*/}
                {/*<Route path="edit" element={<SettingsPage />} />*/}
                {/*<Route path="start" element={<SettingsPage />} />*/}
              </Route>
            }
            { user && user.roles && user.roles.indexOf('creator') != -1 &&
              <Route path="/user" element={<HomeLayout />}>
                <Route path="" element={<ProfilePage />} />
                <Route path="profile" element={<ProfilePage />} />
                <Route path="settings" element={<SettingsPage />} />
              </Route>
            }
          </Routes>
          <SnackbarNotification vertical='bottom' horizontal='center' message={uiStore.snackbar.message} handleCloseSnackbar={uiStore.closeSnackbar}/>
        </ErrorHandler>
      </ViewportProvider>
    </StyledEngineProvider>
  );
})

export default App;