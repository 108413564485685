import {extendObservable, action} from 'mobx';
import gameStore from './gameStore';
import config from '../config.json';
import { isAtWar } from "../logic/gameRules";

export class UiStore {
  constructor() {
    extendObservable(this, {
      snackbar: {
        message: null,
        // open: false,
      },
      drawers: {
        objectives: false,
        history: false,
        stats: false,
      },
      dialog: {
        declarationOfWar: false,
        relations: false,
        stats: false,
      },
      userState: {
        loggedIn: true,
        // joined: false,
        playing: false,
        stats: false,
      },
      redirect: '',
    });

    var timeout;
    var attacker = '';
    var defenders = [];
    var territory = '';
  }

  closeSnackbar = action(() => {
    console.log('close snackbar');
    this.snackbar.message = '';
    // this.snackbar.open = false;
  });

  showSnackbar = action((message, severity) => {
    console.log('set snackbar', message, severity);

    this.snackbar.message = {
      text: message,
      severity: severity ? severity : 'success'
    };
    
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.closeSnackbar, config.SNACKBAR_DURATION);

    // this.snackbar.open = true;
  });

  toggleDrawer = action((drawer, state, closeOthers = true) => {
    this.drawers[drawer] = state;

    // ensure other drawers are closed
    if (closeOthers === true) {
      Object.keys(this.drawers).forEach(d => {
        if (d != drawer) {this.drawers[d] = false}
      })
    }
  });

  toggleDialog = action((dialog, state) => {
    this.dialog[dialog] = state;

    // ensure drawers are closed
    // Object.keys(this.drawers).forEach(d => this.drawers[d] = false)
  });

  showDeclarationOfWarDialog = (attacker, defender, territory, callback) => {
    console.log('showDeclarationOfWarDialog');
    if (attacker == null || defender == null || territory == null || callback == null) {
      return;
    }

    let allDefenders = [defender];

    if (gameStore.players.atWarAlliances.hasOwnProperty(attacker)) {
      for (let alliance of gameStore.players.atWarAlliances[attacker]) {
        if (alliance.includes(defender)) {
          for (let allianceNation of alliance) {
            if (allianceNation != defender && !isAtWar(attacker, allianceNation)) {
              allDefenders.push(allianceNation);
            }
          }
        }
      }
    }

    this.attacker = attacker;
    this.defenders = allDefenders;
    this.territory = territory;
    this.callback = callback;

    this.toggleDialog('declarationOfWar', true);
  };

  setUserState = action((key, value) => {
    console.log('setUserState', key, value);
    this.userState[key] = value;

    if (value === true) {
      localStorage.setItem('uiStore.' + key, value);
    }
    else if (value === false) {
      localStorage.removeItem('uiStore.' + key);
    }
  });

  setRedirect = action(url => {
    console.log('setRedirect', url);
    this.redirect = url;
  })

  resetUI = action(() => {
    this.snackbar.open = false;
    this.drawers = {
        objectives: false,
        history: false,
        stats: false,
      };
    this.dialog = {
      relations: false,
      stats: false,
      };
    this.userState = {
        // loggedIn: false,
        // joined: false,
        playing: false,
        stats: false,
      };
    this.redirect = '';
  })
}

export default new UiStore();