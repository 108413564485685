import gameStore from '../stores/gameStore';
import statisticsStore from '../stores/statisticsStore';

const test = true;

const getIPCTrend = () => {
  
}

const getTerritoryTrend = () => {
  statisticsStore.resetTerritoryTrend();

  const events = gameStore.config.eventOrder === 'top' ? gameStore.events.reverse() : gameStore.events;

  for (let e in events) {
    const event = events[e]
    if (['contest', 'liberate', 'mobilize', 'revert', 'capture'].includes(event.type)) {
      statisticsStore.appendTerritoryTrend(event.info.from, event.info.to);
    }  
  }

  return statisticsStore.getTerritoryTrend();
}


export {getIPCTrend, getTerritoryTrend};

function logger(data, opt) {
  if (test) console.log(data, opt);
}