import { useEffect, useState } from "react";
import { useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { AppBar } from "./AppBar";
import BottomNavigation from "@mui/material/BottomNavigation";
import Box from "@mui/material/Box";

export const HomeLayout = () => {
  const outlet = useOutlet();

  const { user } = useAuth();

  const [pages, setPages] = useState([]);

  useEffect(() => {
    let tempPages = [];

    // console.log('path check roles', user);
      
    if (user && user.roles) {
      // console.log('path check roles', user.roles);
      if (user.roles.indexOf('creator') != -1) {
        tempPages.push({ label: "Home", path: "/game" });
        tempPages.push({ label: "Settings", path: "/user/settings" });
        tempPages.push({ label: "Profile", path: "/user/profile" });
      }
    }

    setPages(tempPages);
  }, [user]);

  return (
    <div>
      <AppBar
        pages={pages}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        sx={{
          backgroundColor: "background.contentFrame"
        }}
      >
        {outlet}
      </Box>
      <BottomNavigation className="BottomNavigation" sx={{
          position: "absolute",
          bottom: "0px",
          left: "0px",
          width: "100%",
          color: "primary.contrastText",
          backgroundColor: "primary.main",
          boxShadow: "0px -2px 4px -1px rgba(0, 0, 0, 0.2),0px -4px 5px 0px rgba(0, 0, 0, 0.14),0px -1px 10px 0px rgba(0, 0, 0, 0.12)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          overflow: "hidden",
        }}>
        {/*{ uiStore.userState.state === uiStore.UI_STATE_PLAYING && gameStore.status.loaded && uiStore.getSessionUserState("page") === "/" && <VictoryConditions /> }*/}
      </BottomNavigation>
    </div>
  );
};
