import { useState } from "react";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { ProfileMenu } from "./ProfileMenu";

export const AppBar = ({ pages }) => {
  const navigate = useNavigate();

  const { user } = useAuth();

  return (
    <MuiAppBar position="absolute">
      <Container maxWidth="xl" sx={{ padding: 0 }}>
        <Toolbar>
          <Box sx={{
            display: "flex",
            flex: 1
          }}>
            <Typography
              variant="h5"
              // className="AAFont stroke"
              // strokeText="Map Tracker"
              component="div"
              sx={{ display: { xs: "none", md: "flex" }, cursor: "pointer" }}
              onClick={() => {
                if (user && user.roles && user.roles.indexOf('creator') != -1) {
                  navigate('/game');
                } 
                else {
                  navigate('/');
                }
              }}
            >
              Map Tracker
            </Typography>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, cursor: "pointer" }}
              onClick={() => {navigate('/')}}
            >
              Map Tracker
            </Typography>
          </Box>
          { !!user &&
            <ProfileMenu pages={pages} />
          }
        </Toolbar>
      </Container>
    </MuiAppBar>
  );
};
