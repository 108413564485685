import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import BarChart from '../BarChart';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Close from 'mdi-material-ui/Close';

// const styles = theme => ({
//   dialogHeader: {
//     backgroundColor: theme.palette.primary.main,
//   },
//   noMargin: {
//     margin: 0,
//   }
// });

export const StatsDialog = observer(({fullScreen, open, handleClose}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    this.setValue(newValue);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        // aria-labelledby='responsive-dialog-title'
        fullWidth={true}
        maxWidth={'lg'}
        PaperProps={{
          sx: {
            backgroundColor: "background.dialog",
          }
        }}
      >
        <DialogTitle id="stats-dialog">
          <Typography width='10px' align='left' variant='h5' gutterBottom={true} className={['AAFont', 'stroke'].join(' ')} stroketext='Statistics'> 
            Statistics
          </Typography>
          <IconButton aria-label="close" onClick={handleClose} sx={{
            position: 'absolute',
            // right: theme.spacing(1),
            // top: theme.spacing(1),
            // color: theme.palette.grey[500],
          }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Economy" />
            <Tab label="Territory" />
          </Tabs>
          <BarChart id='statisticsGraph' type={value} />
        </DialogContent>
      </Dialog>
    </div>
  );
});