import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from "@mui/material/TextField";
// import Link from "@mui/material/Link";
// import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../services/auth.service";

export const VerifyEmailPage = () => {
  const params = useParams();
  
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    console.log('token', params.token);
    let token = params.token;
    AuthService.verifyEmail(token).then(
      () => {
        setLoading(false);
        setSuccess(true);
      },
      (error) => {
        navigate("/resend-email", { replace: true });
      }
    );
    // runs only after params.token changes value.
  }, [params.token]);

  const handleContinue = (event) => {
    navigate("/login", { replace: true });
  }

  return (
    <Container component="main" maxWidth="xs" 
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Account verification
        </Typography>
        {success && <p>Verification successful</p>}
        <LoadingButton
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          loading={loading}
          onClick={handleContinue}
        >
          Go to login
        </LoadingButton>
      </Box>
    </Container>
  );
};
