  import Button from '@mui/material/Button';
  import StandardDialog from './StandardDialog';
  // import DialogActions from '@mui/material/DialogActions';

  const ConfirmDialog = (props) => {
    const { title, label, children, open, handleClose, handleConfirm } = props;

    const actions = () => {
      return (
        <div>
          <Button
            onClick={() => {handleClose()}}
            // color="secondary"
          >
            No
          </Button>
          <Button
            onClick={() => {
              handleConfirm();
              handleClose();
            }}
            // color="primary"
          >
            Yes
          </Button>
        </div>
      );
    };

    return (
      <StandardDialog title={title} label={label ? label : 'confirm-dialog'} open={open} handleClose={handleClose} actions={actions}>
        {children}
      </StandardDialog>
    );
  };

  export default ConfirmDialog;