import React from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/material/styles';
import PropTypes from 'prop-types';
import StandardDialog from './StandardDialog';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
// import withMobileDialog from '@mui/material/withMobileDialog';
import { Heart, SwordCross } from 'mdi-material-ui'

import gameStore from '../../stores/gameStore';

export const RelationsDialog = observer(({ fullScreen, open, handleClose, playerOrder, roundels, currentPlayer, warStatus, handleOnClick }) => {

  const actions = () => {
    return (
      <div>
        <Button
          onClick={() => {handleClose()}}
          // color="secondary"
        >
          Close
        </Button>
      </div>
    );
  };

  return (
    <StandardDialog
      fullScreen={fullScreen}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "background.dialog",
        }
      }}
      title='International Relations'
      label='relations-dialog' 
      open={open} 
      handleClose={handleClose}
      actions={actions}
      // onClose={true}
    >
      <div className='RelationsDialog-grid'>
        <div>
        </div>
        {playerOrder.map((nation, index) => (
          currentPlayer !== nation && gameStore.getTeam(currentPlayer) !== gameStore.getTeam(nation) ?
            <div key={nation} className='RelationsDialog-container'>
              <img 
                src={roundels[nation]}
                alt={'roundel ' + nation}
                className='RelationsDialog-roundel'
              />
            </div>
          :
            null
        ))} 
        <div className='RelationsDialog-container RelationsDialog-row2'>
          <img 
            src={roundels[currentPlayer]}
            alt={'roundel ' + currentPlayer}
            className='RelationsDialog-roundel'
          />
        </div>
        {playerOrder.map((nation, index) => (
          currentPlayer !== nation && gameStore.getTeam(currentPlayer) !== gameStore.getTeam(nation) ? (
              warStatus[currentPlayer].includes(nation) ? 
                <IconButton key={nation} className='RelationsDialog-container RelationsDialog-row2' 
                  onClick={() => {handleOnClick(currentPlayer, nation, false)}}>
                  <SwordCross />
                </IconButton>
              :

                <IconButton key={nation} className='RelationsDialog-container RelationsDialog-row2'
                  onClick={() => {handleOnClick(currentPlayer, nation, true)}}>
                  <Heart />
                </IconButton>
            )                
          :
            null
        ))}
      </div>
    </StandardDialog>
  )
});