import { useState } from "react";
import Box from '@mui/material/Box';
import { IPCValue } from "./IPCValue";
// import { useStore } from "../hooks/useStores";
import gameStore from '../stores/gameStore';
import { observer } from 'mobx-react-lite';

export const IPCTracker = observer(() => {
   
  return (
    <Box sx={{
        fontSize: "22px",
        margin: "auto",
        textShadow: "3px 3px 6px primary.dark",
        // paddingTop: "10px",
        // paddingBottom: "5px",
      }}
    >
      {gameStore.players.order.map((nation, index) => (
        gameStore.players.hasOwnProperty('subEconomy') && gameStore.players.subEconomy.hasOwnProperty(nation) ?
          <IPCValue
            key={nation}
            nation={nation}
            income={[gameStore.players.ipc[nation].income + gameStore.players.ipc[nation].bonus, 
                gameStore.players.ipc[gameStore.players.subEconomy[nation]].income + gameStore.players.ipc[gameStore.players.subEconomy[nation]].bonus]}
            roundel={gameStore.players.roundels[nation]}
            currentPlayer={gameStore.players.current}
            active={[gameStore.cities.capitals[nation], gameStore.cities.capitals[gameStore.players.subEconomy[nation]]]}
            handleOnClick={() => {gameStore.changePlayer(nation)}}
          />
        :    
          <IPCValue
            key={nation}
            nation={nation}
            income={gameStore.players.ipc[nation].income + gameStore.players.ipc[nation].bonus}
            roundel={gameStore.players.roundels[nation]}
            currentPlayer={gameStore.players.current}
            active={gameStore.cities.capitals[nation]} // If no capital is known, make the nation active by default
            handleOnClick={() => {gameStore.changePlayer(nation)}}
          />
      ))}
    </Box>
  );
});