import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link as RouterLink } from "react-router-dom";
import { AuthService } from "../services/auth.service";
import { useAuth } from "../hooks/useAuth";
// import useSnackbar from "../hooks/useSnackbar";

export const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const { login } = useAuth();

  // const { addMessage } = useSnackbar();

  const handleLogin = (event) => {
    event.preventDefault();
    setLoading(true);
    setErrors({email: "", password: ""});
    setMessage("");

    AuthService.login(email, password).then(
      (data) => {
        login(data.user);
        setLoading(false);
        // addMessage("logged in!", "success");  
      },
      (error) => {
        setLoading(false);
        const err = error.response.data.errors;

        if (err) {
          setErrors(err);
        }
        else {
          console.log("error", error);
          const resMessage =
            (error.response && 
              error.response.data && 
              error.response.data.message) ||
            (error.response &&
              error.response.statusText) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
        }
      }
    );
  };

  return (
    <Container component="main" maxWidth="xs" 
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login Here
        </Typography>
        {message && <p className="error-message">{message}</p>}
        <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={event => setEmail(event.target.value)}
            helperText={errors.email}
            error={errors.email ? true : false}
          />
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="password"
            onChange={event => setPassword(event.target.value)}
            helperText={errors.password}
            error={errors.password ? true : false}
          />
          <RouterLink to="/forgot-password">
            <Link href="#" variant="body2">
              {"Forgot your password?"}
            </Link>
          </RouterLink>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
            // loadingPosition="end"
          >
            Login
          </LoadingButton>
          <Grid container>
            <Grid item>
              <RouterLink to="/sign-up">
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </RouterLink>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};
