import { observer } from 'mobx-react-lite';
import Drawer from '@mui/material/Drawer';
import { NationalObjectives } from './NationalObjectives';

import gameStore from '../stores/gameStore';
import uiStore from '../stores/uiStore';

export const NationalObjectivesDrawer = observer(() => {
  
  const handleChange = number => {
    // this.setState({ [name]: event.target.checked });
    console.log('handleChange', number);
    gameStore.setObjectiveStatus(number, !gameStore.objectives[number].status, true);
  };

  return(
    <Drawer
      variant="persistent"
      classes={{
        paper: "drawer", 
      }}
      PaperProps={{
        sx: {
          backgroundColor: "background.drawer",
        }
      }}
      open={uiStore.drawers.objectives}
    >
      <NationalObjectives handleChange={handleChange} />
    </Drawer>
  )
});