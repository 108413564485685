import { useState, useEffect, Fragment } from "react";
import { withStyles } from '@mui/material/styles';
import PropTypes from 'prop-types';
import StandardDialog from './StandardDialog';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import ImageItemPicker from "../ImageItemPicker";
import ItemPicker from "../ItemPicker";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Select from '@mui/material/Select';
import config from '../../config.json';
import { QueryService } from '../../services/query.service';
import { useNavigate } from "react-router-dom";
import { RoundelSettings } from './RoundelSettings';
import { isEqual } from 'lodash';
import gameStore from '../../stores/gameStore';

export const RoundelSettingsDialog = ({ open, fullScreen, teams, nations, initRoundels, handleClose }) => {
  const [roundels, setRoundels] = useState({});
  const [saveEnabled, setSaveEnabled] = useState(false);

  useEffect(() => {
    if (!initRoundels)
      return;
    
    // copy the initial Roundels to prevent changes to alter the original
    let initial = {}
    for (let id in initRoundels) {
      initial[id] = initRoundels[id];
    }
    setRoundels(initial);
  }, [initRoundels]);

  const updateRoundels = (roundels) => {
    setRoundels(roundels);

    if (isEqual(roundels, initRoundels))
      setSaveEnabled(false);
    else
      setSaveEnabled(true);
  }

  const actions = () => {
    return (
      <Box>
        <Button
          onClick={handleClose}
          // color="secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            gameStore.setRoundels(roundels);
            handleClose();
          }}
          // color="secondary"
          disabled={!saveEnabled}
        >
          Save
        </Button>
      </Box>
    );
  };

  return (
    <StandardDialog
      open={open}
      fullScreen={fullScreen}
      title='Roundels'
      label='roundels-setup-dialog' 
      handleClose={handleClose}
      onClose={true}
      actions={actions}
    >
      <Box sx={{
        display: "relative",
        flexDirection: "column",
        overflow: "hidden",
        width: "750px",
      }}>
        <RoundelSettings teams={teams} nations={nations} roundels={roundels} updateRoundels={updateRoundels} />
      </Box>
    </StandardDialog>
  )
};