import { extendObservable, makeObservable, observable, action, computed, autorun, toJS } from 'mobx';
import { changeOwner, nationalObjectives, getVictoryConditions } from '../logic/gameRules.js';
// import Auth from '../modules/Auth';
import io from 'socket.io-client';
import uiStore from './uiStore';
import { QueryService } from '../services/query.service';

const ObjectID = require("bson-objectid");

const socket = io();

const test = true;

/**
* Performs a deep merge of objects and returns new object. Does not modify
* objects (immutable) and merges arrays via concatenation.
*
* @param {...object} objects - Objects to merge
* @returns {object} New object with merged key/values
*/
let mergeDeep = (...objects) => {
  const isObject = obj => obj && typeof obj === 'object';
  
  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach(key => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal);
      }
      else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      }
      else {
        prev[key] = oVal;
      }
    });
    
    return prev;
  }, {});
};


export class GameStore {
  config = {
    eventOrder: 'top',  // 'top' is most recent event on top, 'bottom' is most recent event on bottom
  }

  // data = {
  //   play: '',
  //   rules: '',
  //   scenario: '',
  //   map: '',
  // };

  constructor() {
    // const loaded = computed();
    logger('construct gameStore');
    this.isPreprocessed = false;
    this.preprocessedEvents = false;
    this.preprocessedObjectives = false;
    this.play = 'Ready';

    makeObservable(this, {
      isPlaying: computed,
      isReady: computed,
      isDataLoaded: computed,
      isPlayLoaded: computed,
      isRulesLoaded: computed,
      isScenarioLoaded: computed,
      isMapLoaded: computed,
      isPreprocessed: observable,
      preprocessedTerritories: computed,
      preprocessedEvents: observable,
      preprocessedObjectives: observable,
      loadingState: computed,
      play: observable,
    })

    extendObservable(this, {
      data: {
        play: '',
        rules: '',
        scenario: '',
        map: observable(''),  	  // WAAROM observable alleen op map???
      },
      playId: null,
      territories: [],
      objectives: [],
      rules: {},
      events: [],
      eventHovered: null,
      victoryCities: [],
      map: {},
      players: {
        turn: 1,
        current: '',
        order: [],
        teams: [],
        names: {},
        roundels: {},
        colors: {},
        ipc: {},
        atWar: {},
        atWarAlliances: {},
        vassals: {},
        subEconomy: {},
        noCapital: [],
        limitedPresence: {},
        limitedAttackingNations: {},
      },
      economy: {
        split: {},
        combined: {},
      },
      cities: {
        capitals: {},
        victoryCities: {},
      },
    });

    socket.on('connect', ()  => {
      logger('socket connect', socket);

      socket
        // .emit('authenticate', {token: Auth.getToken()}); //send the jwt
        .emit('authenticate', { token: 'test'} ) //send the jwt
        .on('authenticated', function () {
          //do other things
          logger('socket authenticated!');
        })
        .on('unauthorized', (error) => {
          if (error.data.type == 'UnauthorizedError' || error.data.code == 'invalid_token') {
            // redirect user to login page perhaps?
            logger('User token has expired');
          }
          else {
            logger(`unauthorized: ${JSON.stringify(error.data)}`);
            throw new Error(error.data.type);
          }
        })
    });

    socket.on('event:store', data => {
      // Process Event with showMessage=true
      this.processEvent(data, true);

      // store event
      logger('store event', data);
      this.storeEvent(data);      
    });

    socket.on('event:remove', data => {
      // remove event
      logger('remove event');
      this.removeEvent(data.event);      
    });

    socket.on('play:status', data => {
      logger('play:status', data);
      this.setPlayStatus(data.status);
    });

    autorun(() => {
      logger('gameStore autorun', this.data.play._id);
      if (this.isDataLoaded === true && this.isPreprocessed === false) {
        logger('autorun preprocess');
        this._preprocessData();
      }
      
      if (this.data.play._id === undefined) {
        const playId = localStorage.getItem('playId');
        logger('gameStore autorun playId', playId)
        this.setPlay(playId);
      }
    });
  }

  get isPlaying() {
    let result = this.play === 'Playing' ? true : false;
    return result;
  }

  get isReady() {
    let result = (this.play === 'Ready' || this.play === 'Paused') ? true : false;
    return result;
  }

  get isDataLoaded() {
    let result = this.isPlayLoaded && 
                 this.isRulesLoaded && 
                 this.isScenarioLoaded &&
                 this.isMapLoaded
    return result;
  };

  get isPlayLoaded() {
    let result = this.data.play._id ? true : false;
    return result;
  };

  get isRulesLoaded() {
    let result = this.data.rules._id ? true : false;
    return result;
  };

  get isScenarioLoaded() {
    let result = this.data.scenario._id ? true : false;
    return result;
  };

  get isMapLoaded() {
    let result = this.data.map._id ? true : false;
    return result;
  };

  get preprocessedTerritories() {
    let result = Array.isArray(this.territories) && this.territories.length ? true : false;
    return result;
  };

  get loadingState() {
    if (!this.isPlayLoaded) 
      return "Loading Game"
    if (!this.isRulesLoaded) 
      return "Loading Ruleset"
     if (!this.isScenarioLoaded) 
      return "Loading Scenario"
    if (!this.isMapLoaded) 
      return "Loading Map"
    if (!this.isPreprocessed) 
      return "Preparing Game"
  };
  
  // To Review
  // storeEvent = action(event => {
  //   // event.hover = false;

  //   if (this.config.eventOrder === 'top') {
  //     this.events.unshift(event);
  //   }
  //   else if (this.config.eventOrder === 'bottom') {
  //     this.events.push(event);
  //   }
  // });

  setPlayStatus = action(state => {
    logger('SETPLAYSTATUS', state);
    this.play = state;
  })

  storeEvent = action(event => {
    this.events.push(event);
  });

  hoverEvent = action((index) => {
    this.eventHovered = index;
  });

  hoverEventClear = action(() => {
    this.eventHovered = -1;
  });
  
  // To Review
  removeEvent = action(id => {
    for( let i = 0; i < this.events.length; i++){ 
      if ( this.events[i]._id === id) {
        // undo Event effect
        const event = this.events[i];

        switch (event.type) {
          case 'contest':
          case 'liberate':
          case 'mobilize':  
          case 'revert':
          case 'capture':
            changeOwner(event.info.index, event.info.from, true, false);
            break;
          case 'objective':
            this.setObjectiveStatus(event.info.index, !event.info.status);
            break;
          case 'war-peace':
            // undo war - peace step from player perspective  
            // let index = event.info.player.status.indexOf(event.info.nation.nation);
            // if (index > -1) {
            //   event.info.player.status.splice(index, 1);
            // }
            // // and undo war - peace step from counter parting nation's perspective  
            // index = event.info.nation.status.indexOf(event.info.player.nation);
            // if (index > -1) {
            //   event.info.nation.status.splice(index, 1);
            // }

            // // and update this info
            this.setWarStatus(event.info.player, event.info.nations, event.info.status, false);
            break;
          default:
            return;
        }

        this.events.splice(i, 1); 
      }
    }
  });

  processEvent = (event, showMessage=true) => {
    switch (event.type) {
      case 'capture':
      case 'contest':  
      case 'liberate':
      case 'mobilize':  
      case 'revert':
        changeOwner(event.info.index, event.info.to, showMessage, false);
      break;
      case 'objective':
        this.setObjectiveStatus(event.info.index, event.info.status, false);
        break;
      case 'war-peace':
        this.setWarStatusDirect(event.info.player, event.info.nations, event.info.status);
        break;
      default:
        break;
    }
  };

  // setPlay = action(id => {
  //   if (id !== this.playId) {
  //     // leave socket room
  //     socket.emit('leave', this.playId);

  //     // remove all loaded data
  //     this.resetData();
  //     localStorage.removeItem('playId');
  //   }

  //   this.playId = id;
  //   if (id === null || id === '') {
  //     console.error('PLAYING', id, false)
  //     localStorage.removeItem('playId');
  //     uiStore.setUserState('playing', false);
  //     return;
  //   } 

  //   localStorage.setItem('playId', id);
  //   console.error('PLAYING', id, true)
  //   uiStore.setUserState('playing', true);
  //   // join this play's socket room
  //   socket.emit('join', id);
  //   this.loadData(id);
  // });

  setPlay = action(id => {
    console.error('SET PLAY ID', this.playId)
    if (id !== this.playId && this.playId) {
      // leave socket room
      socket.emit('leave', this.playId);

      // remove all loaded data
      console.error('setPlay resetData()')
      this.resetData();
    }

    this.playId = id;
    if (id === null || id === '') {
      return;
    }

    console.error('SET PLAY ID', id);
    localStorage.setItem('playId', id);
    // join this play's socket room
    socket.emit('join', id);
    this.loadData(id);
  });

  setVictoryCities = action(obj => {
    this.victoryCities = obj;
  });

  setObjectiveStatus = action((index, status, store=false) => {
    logger('setObjectiveStatus', index, status);
    if (store) {
      this.emitStoreEvent({id: this.playId, turn: this.players.turn, player: this.players.current, type: 'objective', info: {index: index, status: status}});
    }

    this.objectives[index].status = status;

    if (status === true) {
      this.objectives[index].ipc = this.data.rules.objectives[index].bonus.value;
    }
    else {
      this.objectives[index].ipc = 0;
    }

    this.evaluateObjectives();
  });

  setWarPeace = action((nation, status) => {
    this.players.atWar[nation] = status;
  });

  setWarStatus = (player, nation, status, store=false) => {
    logger('setWarStatus', player, nation, status);

    // if the player has an atWarAlliance and on of these alliances include the nation for which to toggle the war status, than apply it to all alliance nations
    let nations = [nation];
    
    if (this.players.atWarAlliances.hasOwnProperty(player)) {
      for (let alliance of this.players.atWarAlliances[player]) {
        if (alliance.includes(nation)) {
          for (let allianceNation of alliance) {
            if (allianceNation !== nation) {
              nations.push(allianceNation);
            }
          }
        }
      }
    }
    
    this.setWarStatusDirect(player, nations, status, store);
    
    if (store) {
      this.emitStoreEvent({id: this.playId, turn: this.players.turn, player: this.players.current, type: 'war-peace', info: {player: player, nations: nations, status: status}});
    }

    this.evaluateObjectives();
  };

  setWarStatusDirect = action((player, nations, status) => {
    logger('setWarStatusDirect', player, nations, status);
    
    for (let nation of nations) {
      // If status is set to war (true) and the player is not yet at war with this nation, set the war status
      if (status === true && !this.players.atWar[player].includes(nation)) {
        this.players.atWar[player].push(nation);
        this.players.atWar[nation].push(player);
      }
      // If status is set to peace (false) and the player is not yet at peace with this nation, remove the war status
      else if (status === false && this.players.atWar[player].includes(nation)) {
        this.players.atWar[player].remove(nation);
        this.players.atWar[nation].remove(player);
      }
    }
  })

  resetData = action(() => {
    logger('resetData');
    // After everything is done, check if this is still covering all the variables
    this.isPreprocessed = false;
    this.preprocessedEvents = false;
    this.preprocessedObjectives = false;
    this.play = 'Ready';

    this.data = {
      play: '',
      rules: '',
      scenario: '',
      map: '',
    }

    this.playId = '';
    this.territories = [];
    this.objectives = [];
    this.rules = {};
    this.events = [];
    this.eventHovered = null;
    this.victoryCities = [];
    this.map = {};
    this.players = {
      turn: 1,
      current: '',
      order: [],
      teams: [],
      names: {},
      roundels: {},
      colors: {},
      ipc: {},
      atWar: {},
      atWarAlliances: {},
      vassals: {},
      subEconomy: {},
      noCapital: [],
      limitedPresence: {},
      limitedAttackingNations: {},
    };
    this.economy = {
      split: {},
      combined: {},
    };
    this.cities = {
      capitals: {},
      victoryCities: {},
    };

    localStorage.removeItem('playId');
    console.error('REMOVE ROUNDELS')
    localStorage.removeItem('roundels');
  });

  loadData = (playId) => {
    QueryService.getPlay(playId)
      .then(data => {
        // Save the play data in the this store
        this.setData('play', data);
        this.setPlayStatus(this.data.play.status);

        // Load connected rules, scenario and map
        logger('LOAD RULES, SCENARIO, MAP')
        this.loadRules(this.data.play.rules);
        this.loadScenario(this.data.play.scenario);
        this.loadMap(this.data.play.map);

        // (re)connect to socket
        logger('reconnect socket');      
        socket.disconnect();
        socket.connect();
      })
      .catch((error) => {
        console.error('error catched', error);
        this.setPlay('');
      });
  };

  loadRules = (rulesId) => {
    localStorage.setItem('rulesId', rulesId);

    QueryService.getRuleset(rulesId)
      .then(data => {
        this.setData('rules', data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  loadScenario = (scenarioId) => {
    localStorage.setItem('scenarioId', scenarioId);

    QueryService.getScenario(scenarioId)
      .then(data => {
        this.setData('scenario', data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  loadMap = (mapId) => {
    localStorage.setItem('mapId', mapId);

    QueryService.getMap(mapId)
      .then(data => {
        this.setData('map', data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  setData = action((key, value) => {
    logger('setData', key, value);
    this.data[key] = observable(value);
  })

  _mergeTerritories = action((map, scenario) => {
    let mergedTerritories = mergeDeep(map.territories, scenario.territories);

    this.players.ipc = {}
    this.cities = {
        capitals: {},
        victoryCities: {},
    };
    
    Object.keys(mergedTerritories).forEach((key) => {
      let territory = mergedTerritories[key];
      let owner = territory.own;

      // If territory is not on the map (but is in the scenario)
      if (!territory.hasOwnProperty('name')) {
        // Add empty features
        mergedTerritories[key].name = '';
        // mergedTerritories[key].vc = '';
        mergedTerritories[key].ipc = 0;
        mergedTerritories[key].x = '';
        mergedTerritories[key].y = '';
      }
      else {
        // If territory contains a victory city / capital add it to the cities
        if (territory.hasOwnProperty('vc') && territory.vc !== '') {
          this.cities.victoryCities[territory.vc] = {
            own: owner,
            team: this.getTeam(owner),
            // board: this.getBoard(territory.x),
          }

          if (territory.cap !== false) {
            this.cities.capitals[owner] = true;
          }
        }

        // Add territory IPCs
        this.evaluateIPCs(territory);
      }
    })

    logger('_mergeTerritories', this.cities.capitals);

    return mergedTerritories;
  });

  _preprocessScenario = action(scenario => {
    if (!scenario) {
      logger('ERROR: Preprocessing Scenario without a scenario');
      return;
    }

    logger('_preprocessScenario');

    this.players.order = scenario.players.order;
    this.players.current = this.players.order[0];
    this.players.teams = scenario.players.teams;

    this.players.names = this.players.roundels = this.players.colors = this.players.atWar = 
    this.players.atWarAlliances = this.players.vassals =  this.players.limitedPresence =
    this.players.limitedAttackingNations = [];

    for (const nation of scenario.nations) {
      let id = nation._id;
      this.players.names[id] = nation.name;
      this.players.roundels[id] = nation.roundel;
      this.players.colors[id] = nation.color;
      if (nation.atWar) this.players.atWar[id] = nation.atWar;
      if (nation.atWarAlliances.length > 0) this.players.atWarAlliances[id] = nation.atWarAlliances;
      if (nation.vassalFrom) this.players.vassals[id] = nation.vassalFrom;
      if (nation.limitedPresence.length > 0) this.players.limitedPresence[id] = nation.limitedPresence;
      if (nation.limitedAttackingNations.length > 0) this.players.limitedAttackingNations[id] = nation.limitedAttackingNations;
      if (nation.noCapital === true) this.players.noCapital[id] = true;
    }

    // Overwrite user specific roundels
    const roundels = localStorage.getItem('roundels');
    console.error('ROUNDELS', roundels)
    if (roundels) {
      this.players.roundels = JSON.parse(roundels);
    }
    // Overwrite custom roundels set in Play
    else if (this.data.play.roundels) {
      for (const [key, value] of Object.entries(this.data.play.roundels)) {
        if (this.players.roundels.hasOwnProperty(key)) {
          this.players.roundels[key] = value;
        }
      }
    }
    
    // Create subEconomy list based on vassals data 
    if (this.players.vassals) {
      for (const [key, value] of Object.entries(this.players.vassals)) {
        if (value.ownEconomy === true) {
          this.players.subEconomy[value.nation] = key;
        }
      }
    }

    logger('_preprocessScenario', this.players);
  })

  _preprocessTerritories = action((map, scenario) => {
    if (!map || !scenario) {
      logger('ERROR: Preprocessing Territories without a map and/or scenario');
      return;
    }

    logger('_preprocessTerritories');
    
    // Merge map and scenario data on territory ids
    this.territories = this._mergeTerritories(map, scenario);

    // Add nations with no capital rule active to the capital list
    this.players.noCapital = [];
    for (let nation of scenario.nations) {
      if (nation.noCapital === true) {
        this.cities.capitals[nation._id] = true;
      }
    }
    
  });

  _preprocessObjectives = action((ruleset) => {
    if (typeof ruleset !== 'object') {
      logger('ERROR: Preprocessing Objectives without a ruleset');
      return;
    }

    logger('_preprocessObjectives');

    this.rules = ruleset.rules;
    logger('gameStore.rules', this.rules);

    // If the contested rule is active ensure their is an CON player to convert territories to
    if (this.rules.hasOwnProperty('contested') && this.rules.contested === true) {
      this.players.ipc['CON'] = observable({
        income: 0,
        bonus: 0,
        balance: 0,
      });
    }
    
    // this.objectives = rules.objectives;
    for (let i = 0; i < ruleset.objectives.length; i++) {
      this.objectives[i] = observable({
        ipc: 0,
      });

      if (ruleset.objectives[i].type === 'manual') {
        this.objectives[i].status = ruleset.objectives[i].bonus.initial;
      }
    }

    this.preprocessedObjectives = true;
  });

  _preprocessEvents = action((events) => {
    if (!events)
      return;

    logger('_preprocessEvents');

    this.events = events;

    for (let e in events) {
      let event = events[e]
      logger('event', event);

      // Process Event with showMessage=false
      this.processEvent(event, false);
    }

    this.preprocessedEvents = true;
  });

  _preprocessData = action(() => {
    // Preprocess data
    this._preprocessScenario(this.data.scenario);
    this._preprocessTerritories(this.data.map, this.data.scenario);
    this._preprocessObjectives(this.data.rules);
    this._preprocessEvents(this.data.play.events);
    
    // Evaluate data
    this.evaluateObjectives();
    getVictoryConditions();
    this.isPreprocessed = true;

    logger('Preprocessed capitals', this.cities.capitals);
  });


  /* political: all vassals will report their parent nation
   * economical: all vassals without their own economy will report their parent
   */

  getParentNation = (nation, type) => {
    if (this.players.vassals.hasOwnProperty(nation)) {
      if (type === 'political' || 
              (type === 'economical'
                && this.players.vassals[nation].hasOwnProperty('ownEconomy')
                && this.players.vassals[nation].ownEconomy === false)) {
        return this.players.vassals[nation].nation;
      }
    }

    return nation;
  }

  getVassals = (nation, type) => {
    let vassals = [];
    // console.error('VASSALS STAT', this.players.vassals)
    for (let vassal in this.players.vassals) {
      // console.log('VASSAL', vassal, this.players.vassals[vassal].nation);
      if (this.players.vassals[vassal].nation === nation) {
        // console.log('VASSAL NATION')
        if (type === 'political' || 
              (type === 'economical'
                && this.players.vassals[vassal].hasOwnProperty('ownEconomy')
                && this.players.vassals[vassal].ownEconomy === false)) {
          // console.log('VASSAL PUSH', vassal);
          vassals.push(vassal);
        }
      }
    }

    console.log('VASSALS FINAL', vassals);
    return vassals;
  }

  evaluateIPCs = (territory) => {
    this._updateIPCs(territory, territory.own);
  };

  transferIPCs = (territory, owner) => {
    this._updateIPCs(territory, owner);
    logger('transferIPCs capitals', this.cities.capitals);
  }

  _updateIPCs = (territory, owner) => {
    const duplex = ((territory.own === owner) ? false : true);

    // Change Owner to parent of vassal to ensure money flows to head of vassals
    owner = this.getParentNation(owner, 'economical');

    // Add every new owner to the IPC table
    if (!this.players.ipc.hasOwnProperty(owner)) {
      this.players.ipc[owner] = observable({
        income: territory.ipc,
        bonus: 0,
        balance: 0,
      });
    } 
    else {
      this.players.ipc[owner].income += territory.ipc 
    }

    if (duplex === true) {
      const economicOwner = this.getParentNation(territory.own, 'economical');
      this.players.ipc[economicOwner].income -= territory.ipc;
    }
  }

  evaluateObjectives = action(() => {
    const bonusIpc = nationalObjectives();
    let bonus = 0;
    for (let player in this.players.ipc) {
      if (bonusIpc.hasOwnProperty(player)) {
        bonus = bonusIpc[player];
      }
      else {
        bonus = 0;
      }

      this.players.ipc[player].bonus = bonus;
    }
  });

  undoEvent = (id) => {
    this.emitRemoveEvent({id: this.playId, event: id});
    this.removeEvent(id);
  }

  changeOwnerTerritoryCapture = (index, to, from) => {
    logger('changeOwnerTerritoryCapture');
    this._changeOwnerTerritory('capture', index, to, from)
  };

  changeOwnerTerritoryContest = (index, to, from) => {
    logger('changeOwnerTerritoryContest');
    this._changeOwnerTerritory('contest', index, to, from)
  };

  changeOwnerTerritoryLiberate = (index, to, from) => {
    logger('changeOwnerTerritoryLiberate');
    this._changeOwnerTerritory('liberate', index, to, from)
  };

  changeOwnerTerritoryRevert = (index, to, from) => {
    logger('changeOwnerTerritoryRevert');
    this._changeOwnerTerritory('revert', index, to, from)
  };

  changeOwnerTerritoryMobilized = (index, to, from) => {
    logger('changeOwnerTerritoryMobilized');
    this._changeOwnerTerritory('mobilize', index, to, from)
  };

  _changeOwnerTerritory = (event, index, to, from) => {
    this.emitStoreEvent({id: this.playId, turn: this.players.turn, player: this.players.current, type: event, info: {index: index, to: to, from: from }});
    logger('send event:store', event, index, to, from);
    
    this.changeOwnerTerritoryDirect(index, to);
  };

  changeOwnerTerritoryDirect = action((index, newOwner) => {
    if (newOwner === '' || newOwner === undefined) {
      logger('cannot change owner to', newOwner);
      return;
    }

    let territory = this.territories[index];
    // Update IPC's
    this.transferIPCs(territory, newOwner);
    
    logger(territory.name + ' changed control from ' + territory.own + ' to ' + newOwner);
    
    // Change owner
    territory.own = newOwner;

    // Check Objectives
    this.evaluateObjectives();
  });

  changeOwnerCity = action((city, owner) => {
    this.cities.victoryCities[city].own = owner;
    this.cities.victoryCities[city].team = this.getTeam(owner);
  });

  changePlayer = action(player => {
    this.players.current = player;
  });

  captureCapital = action((nation) => {
    this.cities.capitals[nation] = false;
  });

  liberateCapital = action((nation) => {
    this.cities.capitals[nation] = true;
  });

  getTerritory = index => {
    try {
      return this.territories[index];
    }
    catch {
      return undefined;
    }
  }

  getObjective = index => {
    try {
      return this.data.rules.objectives[index];
    }
    catch {
      return undefined;
    }
  }

  getTeam = nation => {
    for (let team of this.players.teams) {
      if (team.players.includes(nation)) {
        return team.name;
      }
    }

    return undefined;
  };

  setRoundels = action(roundels => {
    if (Object.keys(this.players.roundels).length !== Object.keys(roundels).length) {
      console.error('OUCH', Object.keys(this.players.roundels).length, Object.keys(roundels).length);
      return;
    }
    else {
      for (let id in this.players.roundels) {
        if (!roundels[id] || !this.players.roundels[id]) {
          console.error('OUCH 2', this.players.roundels[id], roundels[id]);
          return;
        }
      }
    }
  
    console.error('SET ROUNDELS', roundels, JSON.stringify(roundels))
    localStorage.setItem('roundels', JSON.stringify(roundels));
    this.players.roundels = roundels;
  })

  emitStoreEvent = action(data => {
    // generate ID for this new event. Or should MongoDB do this automatically?
    data._id = ObjectID().toHexString();

    socket.emit('event:store', data);
    logger('EVENT:STORE', data);
    this.storeEvent(data);
  });

  emitRemoveEvent = action(data => {
    socket.emit('event:remove', data);
    logger('EVENT:REMOVE', data);
    this.removeEvent(data);
  });

  emitPlayStatus = data => {
    socket.emit('play:status', data);
    logger('PLAY:STATUS', data);
  };

}

export default new GameStore();

function logger(msg, ...data) {
  if (test) {
    if (data.length > 0) console.log(msg, data);
    else console.log(msg);
  }
}