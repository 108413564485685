import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "./hooks/useAuth"; 
import reportWebVitals from './reportWebVitals';

import App from "./App";


const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

// const theme = createTheme({
//   palette: {
//     primary: { main: "#3a34d2" }
//   }
// });

const theme = createTheme({
  palette: {
    // Axis & Allies Style
    primary: {
      light: '#665757',
      main: '#403636',
      dark: '#1a1616',
      contrastText: '#fff',
      mainAlpha75: 'rgba(64, 54, 54, 0.75)',
      mainAlpha20: 'rgba(64, 54, 54, 0.20)',
    },
    secondary: {
      light: '#99925c',
      main: '#dab94b',                 //'#807a4d',
      dark: '#66613d',
      contrastText: '#000',
      mainAlpha: 'rgba(218, 185, 75, 0.75)',
    },
    // primary: {
    //   light: '#666ad1',
    //   main: '#303f9f',
    //   dark: '#001970',
    //   contrastText: '#fff',
    //   mainAlpha: 'rgba(48, 63, 159, 0.75)',
    // },
    // secondary: {
    //   light: '#fff350',
    //   main: '#ffc107',                 //'#807a4d',
    //   dark: '#c79100',
    //   contrastText: '#000',
    //   mainAlpha: 'rgba(255, 193, 7, 0.75)',
    // },
    background: {
      contentFrame: '#f0f0f0',
      // drawer: '#d0d0d0',
      drawer: 'rgba(208,208,208,0.9)',
      dialog: '#f0f0f0',
    },
  },
  // fontFamily: 'Barbedor T Heavy',
});

root.render(
  <StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <App sx={{
            margin: "0px",
            padding: "0px",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}/>
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
